import {Component} from '@angular/core';


@Component({
  selector: 'app-error-payment-link',
  templateUrl: './error-payment-link.component.html',
  styleUrls: ['./error-payment-link.component.scss']
})
export class ErrorPaymentLinkComponent {

}
