import { Component } from '@angular/core';

@Component({
  selector: 'app-offer-placeholder',
  templateUrl: './offer-placeholder.component.html',
  styleUrls: ['./offer-placeholder.component.scss']
})
export class OfferPlaceholderComponent {

}
