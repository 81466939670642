import { Component } from '@angular/core';

@Component({
  selector: 'app-success-notmark',
  templateUrl: './success-notmark.component.html',
  styleUrls: ['./success-notmark.component.scss']
})
export class SuccessNotmarkComponent {

}
