import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'kasko', loadChildren: () => import('./modules/kasko/kasko.module').then(m => m.KaskoModule),
        canActivate: []
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      useHash: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
