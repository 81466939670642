<div class="modal-header">
    <h4 class="modal-title pull-left">Оформление без номера</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>Если у вас ещё нет номера или вы планируете его&nbsp;сменить, продолжайте оформление без него.
        Номер можно будет вписать в полис после&nbsp;постановки авто на учёт.</p>
</div>
<div class="modal-footer justify-content-between">
    <div class="d-grid d-sm-flex gap-2 flex-fill justify-content-sm-between actions">
        <button type="button" class="btn btn-outline-primary" (click)="bsModalRef.hide()">Ввести номер авто</button>
        <button type="button" class="btn btn-primary" (click)="processedWithoutLicenseNumber()">Продолжить без номера</button>
    </div>
</div>
