import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {take, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';

// Сервисы
import {FormService} from '../../shared/services/form.service';
import {HttpService} from '../../shared/services/http.service';

import {YandexMetrikaService} from '../../shared/services/yandex-metrika.service';
import {SettingsService} from './settings.service';

// Интерфейсы
import {ICache, ICacheApplicationResponse, ICacheResponse} from '../../shared/interface/cache.interface';
import {IApplication} from '../../shared/interface/application.interface';
import {IUrlParams} from '../../shared/interface/url-params.interface';

@Injectable({
    providedIn: 'root'
})
export class CacheService extends HttpService {

    constructor(@Inject(HttpClient) protected http: HttpClient,
                protected readonly ym: YandexMetrikaService,
                private readonly settingsService: SettingsService,
                private readonly formService: FormService) {
        super(http, ym);
    }

    // Данные из кэша
    public cacheData: ICache = {
        applications: [],
        osagoPolicies: [],
        kaskoPolicies: []
    };

    // Состояние формы до изменения
    public formState = {};

    // Получить данные из кэша
    public getCache(urlParams: IUrlParams): Observable<any> {
        if (!environment.cacheService) {
            return new Observable(subscriber => subscriber.next([]));
        }

        const request = {
            apiKey: this.settingsService.apiKey,
            clientId: localStorage.getItem('clientId')
        };

        const applicationId = this.formService.form.get('applicationId')?.value;
        // return new Observable(subscriber => subscriber.next(this.cacheData));
        return this.post(environment.carInfoApi + 'client/GetCache', request)
            .pipe(
                tap((cacheResponse: ICacheResponse) => {
                    if (cacheResponse.result && cacheResponse.value) {
                        this.cacheData = cacheResponse.value;
                    } else {
                        // Берем данные кэша из localStorage
                        const localStorageCache = localStorage.getItem('insappApp');
                        this.cacheData = localStorageCache ? JSON.parse(JSON.parse(localStorageCache).data) : {};
                    }

                    console.log(this.cacheData);

                    this.cacheData.applications?.find((application) => {
                        if (application.applicationId === applicationId
                            && this.cacheData.kaskoPolicies
                            && this.cacheData.kaskoPolicies.length) {
                            this.cacheData.kaskoPolicies.find((kasko) => {
                                console.log(kasko);
                                console.log(application);
                                if (kasko && kasko.license === application.license
                                    && kasko.kasko && kasko.kasko.carData
                                    && kasko.kasko.carData.brandId != null
                                    && kasko.kasko.carData.modelId != null) {
                                    this.changeApplicationFromCache(kasko.kasko);
                                }
                            });
                        }
                    });
                }),
                take(1)
            );
    }

    // Получить анкету по Id заявки
    public getCacheByApplicationId(): Observable<any> {
        const request = {
            apiKey: this.settingsService.apiKey,
            applicationId: this.formService.form.get('applicationId')?.value
        };

        return this.post(environment.carInfoApi + 'client/GetKaskoCacheByApplicationId', request)
            .pipe(
                tap((cacheResponse: ICacheApplicationResponse) => {
                    // Берем данные кэша из localStorage
                    const localStorageCache = localStorage.getItem('insappApp');
                    this.cacheData = localStorageCache ? JSON.parse(JSON.parse(localStorageCache).data) : { kaskoPolicies: [] };

                    if (cacheResponse.result && cacheResponse.value) {
                        const kasko: IApplication = cacheResponse.value;
                        const license = kasko.license;

                        if (this.cacheData.kaskoPolicies) {
                            const index = this.cacheData.kaskoPolicies.findIndex(x => x.license === kasko.license);
                            if (index !== -1) {
                                this.cacheData.kaskoPolicies[index].kasko = kasko;
                            } else {
                                if (kasko && kasko?.license) {
                                    this.cacheData.kaskoPolicies.push({
                                        license: kasko?.license,
                                        kasko
                                    });
                                }
                            }
                        } else if (license) {
                            this.cacheData = {
                                applications: [],
                                osagoPolicies: [],
                                kaskoPolicies: [{
                                    kasko,
                                    license
                                }]
                            };
                        }
                        this.changeApplicationFromCache(kasko);
                    }
                }),
                take(1)
            );
    }


    // Сохранить данные формы в кэш
    public saveCache(): Observable<any> {
        if (!environment.cacheService) {
            return of(null);
        }
        // if (!this.formService.form.dirty) { return new Observable<any>(); }
        // ApplicationID
        const applicationId = this.formService.form.get('applicationId')?.value;
        // Номер авто
        const license = this.formService.form.get('license')?.value;
        // Данные формы
        const form: IApplication = this.formService.form.value;

        // Если нет номера авто, нет смысла обновлять кэш,
        // только если клиент заполняет анкету "Без номера"
        const isProcessWithoutLicenseNumber = this.formService.form.get('withoutLicenseNumber')?.value;
        if ((!form.license && !isProcessWithoutLicenseNumber)
            || (isProcessWithoutLicenseNumber && !form.carData?.brandId && !form.carData?.modelId)) {
            return of({});
        }

        // Ищем дубликат в кэше с текущим applicationId
        // Если нашли, то обновляем license, если нет, то пушим новый элемент массива
        const findApplicationsItemIndex = this.cacheData?.applications?.findIndex((item) => item.applicationId === applicationId);
        if (findApplicationsItemIndex >= 0) {
            this.cacheData.applications[findApplicationsItemIndex].license = license;
        } else {
            this.cacheData?.applications?.push({
                applicationId,
                license
            });
        }

        const saveFormObj = Object.assign({}, form);

        // Удаляем applicationId, т.к. он не должен сохраняться в данных формы осаго
        // delete saveFormObj.applicationId;
        // Ищем дубликат в кэше с таким же номером авто
        // Если нашли, то обновляем данные формы, если нет, то пушим текущие данные формы
        if (this.cacheData?.kaskoPolicies && this.cacheData?.kaskoPolicies.length) {
            // Если анкета заполняласт "Без номера", то ищем совпадения в brandId и modelId, иначе в номере авто license
            const formItemIndex = this.cacheData?.kaskoPolicies?.findIndex((item) =>
                isProcessWithoutLicenseNumber
                    ? item.kasko?.carData?.brandId === form.carData?.brandId
                    && item.kasko?.carData?.modelId === form.carData?.modelId
                    : item.license === license);

            if (formItemIndex >= 0) {
                this.cacheData.kaskoPolicies[formItemIndex] = {
                    license: saveFormObj.license || '',
                    kasko: saveFormObj
                };
            } else {
                this.cacheData?.kaskoPolicies?.push({
                    license: saveFormObj.license || '',
                    kasko: saveFormObj
                });
            }
        } else {
            this.cacheData.kaskoPolicies = [{
                license: saveFormObj.license || '',
                kasko: saveFormObj
            }];
        }

        // Для сбрасывания кша только для каско
        // this.cacheData.kaskoPolicies = [];

        const request = {
            apiKey: this.settingsService.apiKey,
            clientId: localStorage.getItem('clientId'),
            data: JSON.stringify(this.cacheData)
        };
        localStorage.setItem('insappApp', JSON.stringify(request));
        return this.post(environment.carInfoApi + 'client/UpdateCache', request);
        // return of(null);
    }

    // Выбираем анкету из кэша
    public changeApplicationFromCache(kasko: IApplication): void {
        console.log(kasko);
        // ApplicationID
        kasko.applicationId = this.formService.form.get('applicationId')?.value;
        // console.log(applicationId);

        // this.formService.addDriver();
        this.formService.form.patchValue(kasko);
        this.formService.form.get('cpaClientUid')?.patchValue(kasko.cpaClientUid);
        this.formService.form.get('cpaClientUid2')?.patchValue(kasko.cpaClientUid2);
        this.formService.form.get('applicationId')?.patchValue(kasko.applicationId);
        this.formService.form.get('policy')?.patchValue(true);
        this.formService.form.get('carCharacteristicsRequestId')?.patchValue(kasko.carCharacteristicsRequestId);
        if (kasko.withoutLicenseNumber) {
            this.formService.form.get('license')?.reset();
            this.formService.form.get('license')?.clearValidators();
        } else {
            this.formService.form.get('license')?.patchValue(kasko.license);
        }
        this.formService.form.get('carData')?.patchValue(kasko.carData);
        this.formService.form.get('owner')?.patchValue(kasko.owner);
        if (kasko.insurer) {
            this.formService.form.get('insurer')?.patchValue(kasko.insurer);
        }
        this.formService.form.get('contacts')?.patchValue(kasko.contacts);
        // Добавляем водителей
        if (kasko.drivers && kasko.drivers.length > 0) {
            // Удаляем водителя, которого добавляем по умолчанию

            this.formService.drivers.removeAt(0);
            // Добавляем водителей их кэша
            kasko.drivers?.forEach((driver, index) => {
                this.formService.drivers.push(this.formService.newDriver(driver));
                // TODO переписать, добавлялось много водителей
                // Подписка на эту форму водителя
                this.formService.subscribeFormDriver(this.formService.drivers.controls[index] as UntypedFormGroup);
            });
        }

        // this.formService.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
        // Сбрасываем значение dirty
        // this.formService.form.markAsPristine();

        // Сохраняем текущий стейт формы до ее изменения в отдельную переменную для последующей проверки на изменение
        this.formState = this.formService.form.value;

    }

    // Вставляем анкету осаго в каско
    public changeOsagoApplicationFromCache(kasko: any): void {
        console.log(kasko);
        // ApplicationID
        // const applicationId = this.formService.form.get('applicationId')?.value;
        // console.log(applicationId);

        // this.formService.addDriver();
        // this.formService.form.get('applicationId')?.patchValue(kasko.applicationId);
        this.formService.form.get('license')?.patchValue(kasko.carData.licensePlate);
        this.formService.form.get('carCharacteristicsRequestId')?.patchValue(kasko.carCharacteristicsRequestId);
        // if (kasko.withoutLicenseNumber) {
        //     this.formService.form.get('license')?.reset();
        //     this.formService.form.get('license')?.clearValidators();
        // } else {
        //     this.formService.form.get('license')?.patchValue(kasko.license);
        // }
        this.formService.form.get('carData')?.patchValue(kasko.carData);
        this.formService.form.get('carData')?.get('horsePower')?.setValue(kasko.carData.horsePower);
        this.formService.form.get('carData')?.get('mileage')?.setValue(30000);
        this.formService.form.get('carData')?.get('price')?.setValue(1500000);
        this.formService.form.get('carData')?.get('productionYear')?.setValue(kasko.carData.productionYear);

        this.formService.form.get('carData')?.get('documentType')?.setValue(kasko?.carData?.documentType);

        if (kasko.carData?.identifier === 'Vin') {
            this.formService.form.get('carData')?.get('vinNumber')?.setValue(kasko.carData?.identifier);
        } else if (kasko.carData?.identifier === 'ChassisNumber') {
            this.formService.form.get('carData')?.get('chassisNumber')?.setValue(kasko.carData?.identifier);
        } else if (kasko.carData?.identifier === 'BodyNumber') {
            this.formService.form.get('carData')?.get('bodyNumber')?.setValue(kasko.carData?.identifier);
        }

        this.formService.form.get('unlimitedDrivers')?.setValue(!kasko.hasDriversRestriction);
        this.formService.form.get('ownerIsInsured')?.setValue(kasko.owner.ownerIsInsured);

        // OWNER
        this.formService.form.get('owner')?.get('ownerFirstName')?.setValue(kasko.owner.firstName);
        this.formService.form.get('owner')?.get('ownerLastName')?.setValue(kasko.owner.lastName);
        this.formService.form.get('owner')?.get('ownerMiddleName')?.setValue(kasko.owner.middleName);
        this.formService.form.get('owner')?.get('ownerBirthDate')?.setValue(kasko.owner.birthDate);
        this.formService.form.get('owner')?.get('passportLicense')?.setValue(kasko.owner.passportLicense);
        this.formService.form.get('owner')?.get('ownerPassportDate')?.setValue(kasko.owner.passportIssueDate);
        this.formService.form.get('owner')?.get('ownerCity')?.setValue(kasko.owner.ownerCity);
        this.formService.form.get('owner')?.get('ownerAddressDaData')?.setValue(kasko.owner.ownerAddressDaData);
        this.formService.form.get('owner')?.get('ownerIsJuridical')?.setValue(false);

        // Контакты
        this.formService.form.get('contacts')?.get('email')?.setValue(kasko.contacts.email);
        this.formService.form.get('contacts')?.get('phone')?.setValue(kasko.contacts.phone);

        // Добавляем водителей
        if (kasko.drivers && kasko.drivers.length > 0) {
            // Удаляем водителя, которого добавляем по умолчанию

            this.formService.drivers.removeAt(0);
            // Добавляем водителей их кэша
            kasko.drivers?.forEach((driver: any, index: number) => {
                this.formService.drivers.push(this.formService.newDriver({
                    driverFirstName: driver.driverFirstName,
                    driverLastName: driver.driverLastName,
                    driverMiddleName: driver.driverMiddleName,
                    children: 1,
                    gender: 'Male',
                    rememberOnlyYear: driver.rememberOnlyYear,
                    experienceDate: driver.experienceStartDate,
                    driverBirthDate: driver.driverBirthDate,
                    oldLicense: '',
                    driverLicense: driver.driverLicense.licenseSeries + '' + driver.driverLicense.licenseNumber,
                    familyStatus: 'Married',
                    oldDriverLastName: '',
                    experienceYear: '',
                    licenseWasChanged: 0
                }));
                // TODO переписать, добавлялось много водителей
                // Подписка на эту форму водителя
                this.formService.subscribeFormDriver(this.formService.drivers.controls[index] as UntypedFormGroup);
            });
        }

        // this.formService.form.get('owner')?.patchValue(kasko.owner);
        // if (kasko.insurer) {
        //     this.formService.form.get('insurer')?.patchValue(kasko.insurer);
        // }
        // this.formService.form.get('contacts')?.patchValue(kasko.contacts);
        //
        // // Сохраняем текущий стейт формы до ее изменения в отдельную переменную для последующей проверки на изменение
        // this.formState = this.formService.form.value;

        this.formService.form.get('insurer')?.markAllAsTouched();
        this.formService.form.get('insurer')?.updateValueAndValidity();
    }

    // Очистить кэш
    public resetCache(): void {
        this.cacheData.osagoPolicies = [];
        this.cacheData.kaskoPolicies = [];
        this.cacheData.applications = [];
        const request = {
            apiKey: this.settingsService.apiKey,
            clientId: localStorage.getItem('clientId'),
            data: JSON.stringify(this.cacheData)
        };
        localStorage.setItem('insappApp', JSON.stringify(request));
        this.post(environment.carInfoApi + 'client/UpdateCache', request).subscribe();
    }
}
