import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-modal-invalid-production-year',
    templateUrl: './modal-invalid-production-year.component.html',
    styleUrls: ['./modal-invalid-production-year.component.scss']
})
export class ModalInvalidProductionYearComponent {

    constructor(public readonly bsModalRef: BsModalRef) {
    }

    // Минимальный год выпуска авто
    public minYear!: number;
}
