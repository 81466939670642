<div class="license">
    <form [formGroup]="formService.form" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-4 col-xl-3 pb-4 pb-lg-0 d-none d-lg-block">
                <div class="title">Введите номер автомобиля</div>
                <div>для быстрого расчета цены</div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 offset-xl-1 mb-2 mb-sm-0">
                <div class="input-group input-group-lg license-control">
                    <app-form-license-field class="w-100" formControlName="license"
                                            [isReadonly]="isLoading"></app-form-license-field>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-3">
                <button type="submit" class="shadow-sm w-100 btn btn-primary btn-lg" [disabled]="isLoading">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    <span *ngIf="!isLoading">{{content.licensePage.calcBtn}}</span>
                </button>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-2 mt-2 mt-sm-0">
                <button type="button" (click)="withoutNumber()" class="w-100 btn btn-outline-primary btn-lg" [disabled]="isLoading">
                    {{content.licensePage.calcWithoutNumberBtn}}
                </button>
            </div>
        </div>

        <div class="row mt-2" *ngIf="showCache">
            <div class="col-12 col-sm-12 offset-lg-4 offset-xl-4 col-lg-7">
                <swiper
                    [slidesPerView]="'auto'"
                    [spaceBetween]="10"
                    [freeMode]="true"
                    [pagination]="{clickable: true }">
                    <ng-template swiperSlide *ngFor="let item of cacheService.cacheData.kaskoPolicies">
                        <div class="swipe-card">
                            <button type="button" (click)="changeAppFromCache(item)"
                                    [tooltip]="item.license === formService.form.get('license')?.value
                                    ? 'Текущая анкета уже выбрана' : ''"
                                    container="body"
                                    class="btn btn-outline-primary p-0">
                                <div class="card-body text-start">
                                    <p class="card-subtitle fw-light lh-base mb-0">{{ item.kasko.withoutLicenseNumber ? 'Без номера' :  item.license }}</p>
                                    <p class="card-text mb-0 lh-1">
                                        {{ item.kasko.carData.brandId ? kaskoService.getCarBrandNameById(item.kasko.carData.brandId) : '-' }}
                                    </p>
                                </div>
                            </button>
                            <div class="card-loader" *ngIf="item.isLoading">
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </swiper>
            </div>
        </div>
    </form>

</div>
<!--<pre>{{formService.form.value | json}}</pre>-->
