import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { INotification } from '../../interface/notification.interface';
import { finalize } from 'rxjs/operators';
import { NotificationType } from '../../enums/notification-type.enum';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

    notifications: INotification[] = [];
    private subscription!: Subscription;

    constructor(private notificationSvc: NotificationService) { }

    private _addNotification(notification: INotification): void {
        this.notifications.push(notification);

        if (notification.timeout !== 0) {
            setTimeout(() => this.close(notification), notification.timeout);

        }
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.subscription = this.notificationSvc.getObservable().subscribe(notification => {
            this._addNotification(notification);
        });
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    // --------------------------------------------------------------------------

    public close(notification: INotification): void {
        this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
    }


    public className(notification: INotification): string {
        let style: string;

        switch (notification.type) {

            case NotificationType.success:
                style = 'text-success';
                break;

            case NotificationType.warning:
                style = 'text-warning';
                break;

            case NotificationType.error:
                style = 'text-danger';
                break;

            default:
                style = 'text-info';
                break;
        }

        return style;
    }

}
