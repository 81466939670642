import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

// Сервисы
import { FormService } from '../../../../shared/services/form.service';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { CacheService } from '../../../../core/services/cache.service';
import { KaskoService } from '../../../../shared/services/kasko.service';
import { AppService } from '../../../../core/services/app.service';
import { ValidationService } from '../../../../shared/services/validation.service';

// Перечисления
import { ROUTES } from '../../../../shared/enums/routes.enum';

// Интерфейсы
import { IKaskoPolice } from '../../../../shared/interface/cache.interface';

// Компоненты
import {
    ModalInvalidProductionYearComponent
} from '../../../../shared/components/modal-invalid-production-year/modal-invalid-production-year.component';
import { WithoutLicenseModalComponent } from './without-license-modal/without-license-modal.component';
import { CONTENT } from '../../../../shared/constants/content';
@Component({
    selector: 'app-license',
    templateUrl: './license.component.html',
    styleUrls: ['./license.component.scss']
})
export class LicenseComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('license') license!: ElementRef;
    constructor(public readonly formService: FormService,
                public readonly cacheService: CacheService,
                public readonly kaskoService: KaskoService,
                private readonly appService: AppService,
                private readonly navigationService: NavigationService,
                private readonly modalService: BsModalService,
                private readonly validationService: ValidationService
                ) {
        this.showCache = !appService.isArmApplication
            && cacheService?.cacheData?.kaskoPolicies
            && cacheService?.cacheData?.kaskoPolicies?.length > 0;
    }

    // Отображать кеш
    public showCache = true;

    // Индикатор загрузки
    public isLoading = false;
    // Индикатор загрузки выбор элемента из кэша
    public isLoadingCacheItem = false;
    // Подписка
    private subscriptions: Subscription[] = [];
    // Контент
    public content = CONTENT;
    // Анкета из АРМа
    private _armApplication: IKaskoPolice | null = null;

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        // При заходе на роут, делаем плавный переход вверх
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(
            this.appService.armApplication$
                .subscribe({
                    next: (armApplication) => {
                    if (armApplication != null) {
                        this._armApplication = {
                            license: armApplication.license,
                            kasko: armApplication
                        };
                    }},
                    complete: () => {
                        if (this._armApplication != null) {
                            console.log('switching to arm application', this._armApplication);
                            this.changeAppFromCache(this._armApplication);
                        }
                    }
                }));
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    }

    // --------------------------------------------------------------------------

    // Отправка формы
    public onSubmit(): void {
        // Проверяем валидность номера авто
        if (!this.validationService.validationLicensePage()) {
            this.formService.form.get('license')?.markAsTouched();
            return;
        }
        this.formService.form.markAsUntouched();

        // Номер авто
        const license = this.formService.form.get('license')?.value;
        this.formService.form.get('withoutLicenseNumber')?.setValue(false);
        // Марка авто
        const brandId = this.formService.form.get('carData')?.get('brandId')?.value;
        // this.cacheService.saveCache().subscribe();
        // Проверяем, есть ли такое авто в кэше и есть ли марка авто, если есть, то берем данные с кэша,
        // если нет, то берем данные авто с запроса обогащения
        !this.appService.isArmApplication && this.checkLicenseOnCacheList(license)
            ? this.changeAppFromCache(this.checkLicenseOnCacheList(license))
            : this.createNewApp();
    }

    // Оформить без номера
    public withoutNumber(): void {

        this.subscriptions.push(
            this.modalService.onHidden.subscribe((reason: string | any) => {
                const isProcessWithoutLicenseNumber = this.formService.form.get('withoutLicenseNumber')?.value;
                if (isProcessWithoutLicenseNumber && typeof reason !== 'string') {
                    this.createNewApp();
                } else {
                    // this.ngOnDestroy();
                }
            })
        );

        this.modalService.show(WithoutLicenseModalComponent, Object.assign({}, { class: 'modal-dialog modal-dialog-centered modal-without-number' }));
    }

    // Выбрали анкету из кэша
    public changeAppFromCache(item: IKaskoPolice): void {

        // ApplicationID
        // const applicationId = this.formService.form.get('applicationId')?.value;
        // console.log(this.formService.form.get('applicationId'));
        // console.log(applicationId);


        item.isLoading = true;
        // Сбрасываем форму
        // this.formService.form.reset();
        // Чистим массив водителей
        while (this.formService.drivers.length !== 0) {
            this.formService.drivers.removeAt(0);
        }
        // Применяем нмоер авток  форме, выбранный из карточки кэша
        this.formService.form.get('license')?.setValue(item.kasko.license);



        // Если в анкете из кэша есть выбранный бренд авто и год производства авто валиден,
        // значит делаем запросна на получение моделей по бренду авто и переходим дальше на страницу инфо.
        if (item.kasko.carData.brandId && item.kasko.carData.productionYear
            && this.validationProductionYear(item.kasko.carData.productionYear)) {
            this.subscriptions.push(
                this.kaskoService.getCarModels(item.kasko.carData.brandId)
                    .pipe(
                        map(() => this.kaskoService.getAppInfo()),
                        finalize(() => {
                            item.isLoading = false;
                            this.isLoading = false;
                        }),
                        tap(take(1))
                    )
                    .subscribe((res) => {
                        // Применяем данные анкеты из кэша к текущей форме
                        this.cacheService.changeApplicationFromCache(item.kasko);
                        // Переходим на страницу данных авто
                        this.navigationService.navigate(ROUTES.AutoInfoPage, {applicationId: item.kasko.applicationId});
                    })
            );
        } else if (item.kasko.carData.productionYear && !this.validationProductionYear(item.kasko.carData.productionYear)) {
            item.isLoading = false;
            this.isLoading = false;
            // Показываем уведомление о том что авто слишком старое
            this.showModalValidationProductionYear();
        } else {
            item.isLoading = false;
            this.isLoading = false;
            // Применяем данные анкеты из кэша к текущей форме
            this.cacheService.changeApplicationFromCache(item.kasko);
            // Переходим на страницу данных авто
            this.navigationService.navigate(ROUTES.AutoInfoPage, {applicationId: item.kasko.applicationId});
        }
    }

    // Берем данные авто по номеру и переходи на страницу данных авто
    // У нас есть номер авто, при этом, в кэше нету анкеты с таким номером авто,
    // значит мы должны сделать запрос new, что бы получить новый applicationId и сохранить новую анкету в кэше
    // после запроса new, сразу делаем запрос setWidgetDisplay, хз зачем он, но так сейчас работает бэк.
    // Далее мы делаем запрос checkCarCharacteristics на поулчение данных авто по его номеру
    public createNewApp(): void {
        this.isLoading = true;
        // Если заполняем анкету без номера
        const isProcessWithoutLicenseNumber = this.formService.form.get('withoutLicenseNumber')?.value;

        // почему Map? у тебя же внутри там подписки идут, что он вернет в Subscription в этом случае?
        // заменил на switchMap
        this.subscriptions.push(
            this.appService.createApplication()
                .pipe(
                    switchMap(() => isProcessWithoutLicenseNumber ? of(true) : this.appService.checkCarCharacteristics()),
                    switchMap((res: any) => {
                        if (res.result && res?.value?.status === 'Complete' && res?.value?.carData) {
                            // Минимальный разрешающий год выпуска авто = колчество значений в списке productionYearList
                            if (this.validationProductionYear(res.value?.carData?.productionYear)) {
                                // Запро на получение моделей авто
                                return this.kaskoService.getCarModels(res.value.carData.brandId).pipe(
                                    map(() => res),
                                    finalize(() => {
                                        // Применяем полученные данные авто в форму
                                        this.formService.setCarCharacteristics(res.value.carData);
                                    })
                                );
                            } else {
                                // Показываем уведомление о том что авто слишком старое
                                this.showModalValidationProductionYear();
                                return of(res);
                            }
                        } else {
                            return of(res);
                        }
                    }),
                    finalize(() => this.isLoading = false),
                )
                .subscribe((res) => {
                    // Какой бы ответ не был, мы все равно переходим на следующую страницу авто, только если productionYear валиден
                    if (this.validationProductionYear(res.value?.carData?.productionYear) || !res.result || isProcessWithoutLicenseNumber) {
                        this.kaskoService.getOwnedSinceList(res.value?.carData?.productionYear);
                        this.navigationService.navigate(ROUTES.AutoInfoPage);
                    }
                })
        );
    }

    // В зависимости от ответа от запроса обогащения, либо делаем запрос на получение модели и идем далее,
    // либо проверяем, если полученная дата выпуска меньше допустимого, то показываем уведомление
    // иначе, прееходим на страницу данных авто
    private resolveCarCharacteristics(res: any): Observable<boolean> {
        if (res.result && res?.value?.status === 'Complete' && res?.value?.carData) {
            // Минимальный разрешающий год выпуска авто = колчество значений в списке productionYearList
            if (this.validationProductionYear(res.value?.carData?.productionYear)) {
                // Запро на получение моделей авто
                return this.kaskoService.getCarModels(res.value.carData.brandId).pipe(
                    () => of(true),
                    finalize(() => {
                        // Применяем полученные данные авто в форму
                        this.formService.setCarCharacteristics(res.value.carData);
                    })
                );
            } else {
                // Показываем уведомление о том что авто слишком старое
                this.showModalValidationProductionYear();
                return of(false);
            }
        }
        return of(true);
    }

    // Валидация минималньо допустимого значения года производства авто
    public validationProductionYear(productionYear: number): boolean {
        const minYear = Number(this.kaskoService.productionYearList[0].value);
        return productionYear >= minYear;
    }

    // Показываем окно валидации минималнього года выпуска авто
    public showModalValidationProductionYear(): void {
        const initialState: ModalOptions = {
            initialState: {
                minYear: this.kaskoService.productionYearList[0].value,
                class: 'modal-dialog modal-dialog-centered'
            }
        };
        this.modalService.show(ModalInvalidProductionYearComponent, initialState);
    }

    // Проверяем, есть ли такое авто в кэше
    public checkLicenseOnCacheList(license: string): any {
        return this.cacheService.cacheData?.kaskoPolicies?.find((item: IKaskoPolice) =>
            item.license === license
            && item.kasko
            && item.kasko.carData
            && item.kasko.carData.brandId
            && item.kasko.carData.modelId);
    }
}
