<div class="card mb-3 h-auto shadow-sm p-0 rounded-4 info-filter-block">
    <div class="card-body p-0">
        <div class="row p-3 p-sm-4" (click)="isShowEditInfo = !isShowEditInfo"
             [attr.aria-expanded]="!isShowEditInfo" aria-controls="collapseBasic">
            <div class="col-auto">
                <img ngSrc="./assets/images/icons/policy-data.svg" alt="" height="48" width="48">
            </div>
            <div class="col px-2 d-flex flex-column justify-content-center align-items-start">
                <h5 class="mb-1">Данные полиса</h5>
                <div class="second-title-item d-block d-sm-none text-truncate">
                    {{ kaskoService.getCarBrandNameById() }}
                    {{ kaskoService.getCarModelNameByModelId() }} |
                    {{ formService.form.value?.owner?.ownerFirstName }}
                    {{ formService.form.value?.owner?.ownerLastName }}
                </div>
                <div class="d-none d-sm-block">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item">
                                {{ kaskoService.getCarBrandNameById() }}
                                {{ kaskoService.getCarModelNameByModelId() }}
                            </li>
                            <li class="breadcrumb-item d-none d-sm-block">
                                {{ formService.form.value?.carData?.horsePower }} л.с.
                            </li>
                            <li class="breadcrumb-item d-none d-sm-block">
                                {{ formService.form.value?.carData?.productionYear }} год
                            </li>
                            <li class="breadcrumb-item">
                                {{ formService.form.value?.owner?.ownerFirstName }}
                                {{ formService.form.value?.owner?.ownerLastName }}
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="col-auto d-flex align-items-center justify-content-center">
                <div class="d-flex justify-content-between">
                    <a class="btn-edit">
                        <svg *ngIf="isShowEditInfo" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9877 16.8002C11.3311 16.8012 10.6807 16.6925 10.0739 16.4804C9.46704 16.2682 8.91577 15.9568 8.45171 15.564L0.280712 8.65397C0.0985538 8.4945 -0.00224062 8.2809 3.78026e-05 8.0592C0.00231622 7.83749 0.107485 7.62541 0.292893 7.46864C0.478301 7.31186 0.729114 7.22293 0.991311 7.22101C1.25351 7.21908 1.50611 7.30431 1.69471 7.45834L9.86571 14.3683C10.429 14.8434 11.1921 15.1101 11.9877 15.1101C12.7833 15.1101 13.5464 14.8434 14.1097 14.3683L22.2807 7.45834C22.373 7.37758 22.4833 7.31316 22.6053 7.26884C22.7273 7.22453 22.8585 7.2012 22.9913 7.20023C23.1241 7.19925 23.2558 7.22065 23.3787 7.26316C23.5016 7.30568 23.6132 7.36846 23.7071 7.44786C23.801 7.52725 23.8753 7.62166 23.9255 7.72558C23.9758 7.82949 24.0011 7.94084 24 8.05311C23.9988 8.16539 23.9712 8.27634 23.9188 8.3795C23.8664 8.48267 23.7902 8.57597 23.6947 8.65397L15.5237 15.564C15.0597 15.9568 14.5084 16.2682 13.9016 16.4804C13.2948 16.6925 12.6443 16.8012 11.9877 16.8002Z" fill="#98A2B3"/>
                        </svg>
                        <svg *ngIf="!isShowEditInfo" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2453_33942)">
                                <path d="M12.0123 7.20006C12.6689 7.19904 13.3193 7.30772 13.9261 7.51987C14.533 7.73202 15.0842 8.04345 15.5483 8.43628L23.7193 15.3463C23.9014 15.5057 24.0022 15.7193 24 15.941C23.9977 16.1628 23.8925 16.3748 23.7071 16.5316C23.5217 16.6884 23.2709 16.7773 23.0087 16.7792C22.7465 16.7812 22.4939 16.6959 22.3053 16.5419L14.1343 9.63191C13.571 9.15687 12.8079 8.8901 12.0123 8.8901C11.2167 8.8901 10.4536 9.15687 9.89029 9.63191L1.71929 16.5419C1.62704 16.6227 1.5167 16.6871 1.39469 16.7314C1.27269 16.7757 1.14147 16.799 1.00869 16.8C0.87591 16.801 0.744232 16.7796 0.621336 16.7371C0.49844 16.6946 0.386787 16.6318 0.292894 16.5524C0.199001 16.473 0.124748 16.3786 0.0744668 16.2747C0.0241852 16.1707 -0.00111573 16.0594 3.82215e-05 15.9471C0.00119218 15.8349 0.0287801 15.7239 0.0811883 15.6207C0.133597 15.5176 0.209778 15.4243 0.305288 15.3463L8.47629 8.43628C8.94035 8.04345 9.49162 7.73202 10.0984 7.51987C10.7052 7.30772 11.3557 7.19904 12.0123 7.20006Z" fill="#98A2B3"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_2453_33942">
                                    <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(-180)"/>
                                </clipPath>
                            </defs>
                        </svg>

                    </a>
                    <!--                        <button type="button" class="btn btn-outline-primary btn-sm btn-edit btn-edit-icon" (click)="openEditInfoModal()">-->
                    <!--                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path d="M20.2929 7.20711C20.6834 6.81658 20.6834 6.18342 20.2929 5.7929L18.2071 3.70711C17.8166 3.31658 17.1834 3.31658 16.7929 3.70711L15 5.5L18.5 9L20.2929 7.20711Z" fill="#C5C5C7"/>-->
                    <!--                                <path d="M7.5 20L18 9.5L14.5 6L4 16.5V20H7.5Z" fill="#C5C5C7"/>-->
                    <!--                            </svg>-->
                    <!--                        </button>-->
                </div>
            </div>
        </div>

        <div id="collapseBasic" [collapse]="isShowEditInfo" [isAnimated]="true">
            <app-edit-info></app-edit-info>
        </div>


    </div>
</div>
