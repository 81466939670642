import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Компоненты
import { LicenseComponent } from './components/license/license.component';
import { AutoComponent } from './components/auto/auto.component';
import { DriversComponent } from './components/drivers/drivers.component';
import { OwnerComponent } from './components/owner/owner.component';
import { OffersComponent } from './components/offers/offers.component';
import { CreatePolicyComponent } from './components/create-policy/create-policy.component';
import { SuccessComponent } from './components/success/success.component';

// Перечисления
import { ROUTES } from '../../shared/enums/routes.enum';
import { SuccessNotmarkComponent } from './components/success-notmark/success-notmark.component';


const routes: Routes = [
    {
        path: ROUTES.MainPage,
        component: LicenseComponent
    },
    {
        path: ROUTES.AutoInfoPage,
        component: AutoComponent
    },
    {
        path: ROUTES.SuccessNotMark,
        component: SuccessNotmarkComponent
    },
    {
        path: ROUTES.Drivers,
        component: DriversComponent
    },
    {
        path: ROUTES.Owner,
        component: OwnerComponent
    },
    {
        path: ROUTES.Offers,
        component: OffersComponent,
    },
    {
        path: ROUTES.CreatePolicy,
        component: CreatePolicyComponent
    },
    {
        path: ROUTES.Success,
        component: SuccessComponent
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class KaskoRoutingModule { }
