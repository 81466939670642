import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {conformToMask} from 'angular2-text-mask';
import {KaskoService} from '../../../services/kasko.service';
import {FormService} from '../../../services/form.service';
import {AppService} from '../../../../core/services/app.service';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-edit-info',
  templateUrl: './edit-info.component.html',
  styleUrls: ['./edit-info.component.scss']
})
export class EditInfoComponent implements OnInit, OnDestroy {

  constructor(public readonly formService: FormService,
              public readonly appService: AppService,
              private readonly navigationService: NavigationService,
              public readonly kaskoService: KaskoService) {
  }

  // Модальное окно
  // public modalRef?: BsModalRef;
  // Данные формы
  public formData = this.formService.form.value;
  // Подписка
  public subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;
  public dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
  public passportLicense = [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public phone = ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  // Выбранный оффер
  public selectedOffer = this.kaskoService.selectedOffer;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {

  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  // Переход по роуту
  public navigate(route: string): void {
    this.navigationService.navigate(route);
  }

  // Возвращаем дату в формате маски
  public getDateMask(value: string): string {
    if (value) {
      const conformedValue = conformToMask(value, this.dateMask, {});
      return conformedValue.conformedValue;
    } else {
      return '';
    }
  }

  // Возвращаем паспортные данные в формате маски
  public getPassportLicenseMask(value: string): string {
    if (value) {
      const conformedValue = conformToMask(value, this.passportLicense, {});
      return conformedValue.conformedValue;
    } else {
      return '';
    }
  }

  // Возвращаем номер телефона в формате маски
  public getPhoneMask(value: string): string {
    if (value) {
      const conformedValue = conformToMask(value, this.phone, {});
      return conformedValue.conformedValue;
    } else {
      return '';
    }
  }
}
