import {Component, Input, OnInit} from '@angular/core';
import {KaskoService} from '../../services/kasko.service';
import {IOffer} from '../../interface/offers.interface';

// Сервисы

// Перечисления


@Component({
    selector: 'app-selected-offer',
    templateUrl: './selected-offer.component.html',
    styleUrls: ['./selected-offer.component.scss']
})
export class SelectedOfferComponent implements OnInit {

    @Input() paymentLink!: string;

    // Выбраннй оффер
    public selectedOffer!: IOffer;
    public isPaymentCountdownStarted = false;
    public paymentCountdown = '02:30';
    private countdownInterval: any;

    constructor(private readonly kaskoService: KaskoService) {
    }


    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        if (this.kaskoService.selectedOffer) {
            this.selectedOffer = this.kaskoService.selectedOffer;
        }

        this.startPaymentCountdown();
    }

    // --------------------------------------------------------------------------

    // Счетчик
    public startPaymentCountdown(): void {
        this.isPaymentCountdownStarted = true;

        // начинаем обратный отсчет
        this.countdownInterval = setInterval(() => {
            const timeParts = this.paymentCountdown.split(':');
            let minutes = +timeParts[0];
            let seconds = +timeParts[1];

            if (minutes === 0 && seconds === 0) {
                clearInterval(this.countdownInterval);
                this.isPaymentCountdownStarted = false;
                return;
            }

            if (seconds === 0) {
                minutes--;
                seconds = 59;
            } else {
                seconds--;
            }

            this.paymentCountdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }, 1000);
    }

    public pay(): void {
        window.location.href = this.kaskoService.paymentLink;
    }


}
