import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

// Модули
import { KaskoRoutingModule } from './kasko-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';

// Компоненты
import { LicenseComponent } from './components/license/license.component';
import { AutoComponent } from './components/auto/auto.component';
import { DriversComponent } from './components/drivers/drivers.component';
import { OwnerComponent } from './components/owner/owner.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DriverComponent } from './components/drivers/driver/driver.component';
import { OffersComponent } from './components/offers/offers.component';
import { SuccessComponent } from './components/success/success.component';
import { CacheItemComponent } from './components/license/cache-item/cache-item.component';
import { TextMaskModule } from 'angular2-text-mask';
import { WithoutLicenseModalComponent } from './components/license/without-license-modal/without-license-modal.component';
import { SwiperModule } from 'swiper/angular';
import { OfferComponent } from './components/offers/offer/offer.component';
import { FilterOffersComponent } from './components/offers/filter-offers/filter-offers.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {CreatePolicyComponent} from './components/create-policy/create-policy.component';
import {ErrorPaymentLinkComponent} from "./components/create-policy/error-payment-link/error-payment-link.component";
import { SuccessNotmarkComponent } from './components/success-notmark/success-notmark.component';

// Основной модуль КАСКО
@NgModule({
    declarations: [
        LicenseComponent,
        AutoComponent,
        DriversComponent,
        OwnerComponent,
        DriverComponent,
        OffersComponent,
        SuccessComponent,
        CacheItemComponent,
        WithoutLicenseModalComponent,
        OfferComponent,
        FilterOffersComponent,
        CreatePolicyComponent,
        ErrorPaymentLinkComponent,
        SuccessNotmarkComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        KaskoRoutingModule,
        ReactiveFormsModule,
        TypeaheadModule,
        PopoverModule,
        TooltipModule.forRoot(),
        TextMaskModule,
        ModalModule.forRoot(),
        SwiperModule,
        CollapseModule,
        NgOptimizedImage
    ],
    exports: [
    ],
    providers: [BsModalRef]
})
export class KaskoModule {
}
