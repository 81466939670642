<h3 class="page-title mt-4 mb-4">Создание полиса</h3>

<app-policy-data [isShowEditInfo]="true"></app-policy-data>

<app-selected-offer *ngIf="isPaymentLinkResult" [paymentLink]="kaskoService.paymentLink"></app-selected-offer>

<div *ngIf="!isPaymentLinkResult">
        <app-error-payment-link></app-error-payment-link>

        <h3 class="page-title mt-4 mb-4">Другие предложения</h3>

        <div class="card mb-4 h-auto shadow-sm rounded-4" *ngIf="isLoading">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Поиск предложений</h5>
                    </div>
                    <div class="col-auto">
                        <app-timer [min]="2" [sec]="0" ></app-timer>
                    </div>
                </div>
                <div class="progress mb-2">
                    <div class="progress-bar" role="progressbar" [style]="'width:' + (timer / 2) * 100 / 60 + '%;'"></div>
                </div>
            </div>
        </div>

        <div class="offers-list">
            <div *ngFor="let offer of kaskoService.offers">
                <app-offer [offer]="offer" (changeOffer)="onChangeOffer($event);"></app-offer>
            </div>
        </div>

        <app-offer-placeholder *ngIf="isLoading"></app-offer-placeholder>
</div>

<!--<pre>{{osagoService.offers|json}}</pre>-->
