import { Injectable } from '@angular/core';
import { FormService } from './form.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TextMaskHelper } from '../../shared/helpers/textmaskHelper';
import moment from 'moment-mini';
import { IProperty } from '../interface/property-controls.interface';

// Сервис валидации формы
@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    constructor(private readonly formService: FormService) {
    }

    // Валидация номера авто и региона на главной странице
    public validationLicensePage(): boolean {
        const isProcessWithoutLicenseNumber = this.formService.form.get('withoutLicenseNumber')?.value;
        return isProcessWithoutLicenseNumber ? isProcessWithoutLicenseNumber : this.formService.form.get('license')?.valid as boolean;
    }

    // Валидация контроллов на странице auto-info
    public validationNotMarkPage(): boolean {
        return this.formService.form.get('withoutCarBrand')?.valid as boolean;
    }

    // Валидация контроллов на странице auto-info
    public validationAutoInfoPage(): boolean {
        return this.formService.form.get('carData')?.valid as boolean;
    }

    // Валидация контроллов на странице drivers
    public validationDriversPage(): boolean {
        return (this.formService.form.get('drivers')?.valid as boolean
                || this.formService.form.get('unlimitedDrivers')?.value);
    }

    // Валидация контроллов на странице owner
    public validationOwnerPage(): boolean {
        // Если страхователь НЕ является собственником авто
        if (!this.formService.form.get('ownerIsInsured')?.value) {
            return this.formService.form.get('owner')?.valid as boolean
                && this.formService.form.get('insurer')?.valid as boolean
                && this.formService.form.get('contacts')?.valid as boolean
                && this.formService.form.get('policy')?.valid as boolean;
        } else {
            return this.formService.form.get('owner')?.valid as boolean
                && this.formService.form.get('contacts')?.valid as boolean
                && this.formService.form.get('policy')?.valid as boolean;
        }
    }

    // Валидация контроллов на странице owner
    public validationContacts(): boolean {
        return this.formService.form.get('contacts')?.valid as boolean;
    }

    // Применяем валидацию для контролла
    public setControlValidation(propertyControl: IProperty, control: UntypedFormControl): void {
        const validators: ValidatorFn | ValidatorFn[] | null = [];
        if (control && propertyControl && propertyControl?.validation?.values) {
            propertyControl?.validation?.values?.forEach((item) => {
                switch (item.validName) {
                    case 'required':
                        validators.push(Validators.required);
                        break;
                    case 'email':
                        validators.push(Validators.email);
                        break;
                    case 'min':
                        validators.push(Validators.min(item.validValue || 0));
                        break;
                    case 'max':
                        validators.push(Validators.max(item.validValue || 999));
                        break;
                    case 'minLength':
                        validators.push(Validators.minLength(item.validValue || 0));
                        break;
                    case 'maxLength':
                        validators.push(Validators.maxLength(item.validValue || 999));
                        break;
                    case 'pattern':
                        validators.push(Validators.pattern(item.validValue));
                        break;
                    case 'birthDate':
                        validators.push(this.birthDate());
                        break;
                    case 'ownerBirthDate':
                        validators.push(this.birthDate('ownerPassportDate'));
                        break;
                    case 'insurerBirthDate':
                        validators.push(this.birthDate('insurerPassportDate'));
                        break;
                    case 'experienceDate':
                        validators.push(this.experienceStartDate());
                        break;
                    case 'experienceYear':
                        validators.push(this.experienceStartYear());
                        break;
                    case 'documentDate':
                        validators.push(this.documentDate());
                        break;
                    case 'ownerPassportDate':
                        validators.push(this.passportDate('ownerBirthDate'));
                        break;
                    case 'insurerPassportDate':
                        validators.push(this.passportDate('insurerBirthDate'));
                        break;
                    case 'ownerDaDataRequired':
                        validators.push(this.daDataRequired('ownerAddressDaData'));
                        break;
                    case 'ownerOnlyCityDaDataRequired':
                        validators.push(this.daDataOnlyCityRequired('ownerAddressDaData'));
                        break;
                    case 'insurerOnlyCityDaDataRequired':
                        validators.push(this.daDataOnlyCityRequired('insurerAddressDaData'));
                        break;
                    case 'productionYear':
                        validators.push(this.productionYear());
                        break;
                    case 'ownedSince':
                        validators.push(this.ownedSince('productionYear'));
                        break;
                }
            });
            control.setValidators(validators);
        }

        return;
    }

    // Валидация даты рождения
    public birthDate(dependedControlName?: string): ValidatorFn {
        return (fc: AbstractControl) => {
            const value = TextMaskHelper.getDate(fc.value);

            const date = moment(value || '', 'DD.MM.YYYY', true);
            if (!date.isValid() || date.isAfter()) {
                return {
                    customValidation: true,
                    message: 'Укажите корректную дату рождения'
                };
            }

            if (moment(value || '', 'DD.MM.YYYY', true).add(16, 'years').isAfter()) {
                return {
                    customValidation: true,
                    message: 'Водителю должно быть больше 16 лет'
                };
            }

            if (!moment(value || '', 'DD.MM.YYYY', true).add(100, 'years').isAfter()) {
                return {
                    customValidation: true,
                    message: 'Водителю не должно быть больше 100 лет'
                };
            }

            const experienceDate = (fc.parent as UntypedFormGroup)?.get('experienceDate');
            if (experienceDate && !experienceDate.valid) {
                experienceDate?.updateValueAndValidity();
                return null;
            }

            // const experienceDateValue = experienceDate ? TextMaskHelper.getDate(experienceDate?.value) : null;
            //
            // if (experienceDateValue && date.add(16, 'year').isAfter(moment(experienceDateValue, 'DD.MM.YYYY'))) {
            //     return {
            //         customValidation: true,
            //         message: 'Стаж начался в возрасте до 16 лет'
            //     };
            // }
            //
            // if (dependedControlName) {
            //     const dependedControl = (fc.parent as FormGroup)?.get(dependedControlName);
            //     if (dependedControl && !dependedControl.valid) {
            //         dependedControl?.updateValueAndValidity();
            //         return null;
            //     }
            //
            //     const dependedControlValue = dependedControl ? TextMaskHelper.getDate(dependedControl?.value) : null;
            //
            //     if (dependedControlValue && date && date?.add(16, 'year')?.isAfter(moment(dependedControlValue, 'DD.MM.YYYY'))) {
            //         return {
            //             customValidation: true,
            //             message: 'Стаж начался в возрасте до 16 лет'
            //         };
            //     }
            // }

            return null;
        };
    }

    // Валидация даты начала стажа
    public experienceStartDate(ignore = () => false, birthDateFieldName: string = 'driverBirthDate'): ValidatorFn {
        return (fc: AbstractControl) => {
            const value = TextMaskHelper.getDate(fc.value);
            if (value == null) {
                return {
                    customValidation: true,
                    message: 'Укажите корректную дату'
                };
            }

            const date = moment(value, 'DD.MM.YYYY', true);
            if (!date.isValid() || date.isAfter()) {
                return {
                    customValidation: true,
                    message: 'Укажите корректную дату'
                };
            }

            const birthDate = (fc.parent as UntypedFormGroup)?.get(birthDateFieldName);
            if (birthDate && !birthDate.valid) {
                birthDate?.updateValueAndValidity();
                return null;
            }

            const birthDateValue = birthDate ? TextMaskHelper.getDate(birthDate.value) : null;
            if (birthDateValue && moment(birthDateValue, 'DD.MM.YYYY', true).add(16, 'year').isAfter(date)) {
                return {
                    customValidation: true,
                    message: 'Стаж начался в возрасте до 16 лет'
                };
            }

            return null;
        };
    }

    public experienceStartYear(ignore = () => false, birthDateFieldName: string = 'driverBirthDate'): ValidatorFn {
        return (fc: AbstractControl) => {

            const value = TextMaskHelper.getYear(fc.value);
            if (value == null || moment().year() < Number(value)) {
                return {
                    customValidation: true,
                    message: 'Укажите корректный год'
                };
            }

            const date = moment('31.12.' + value, 'DD.MM.YYYY', true);
            if (!date.isValid()) {
                return {
                    customValidation: true,
                    message: 'Укажите корректный год'
                };
            }

            const birthDate = (fc.parent as UntypedFormGroup)?.get(birthDateFieldName);
            if (!birthDate?.valid) {
                return null;
            }

            const birthDateValue = TextMaskHelper.getDate(birthDate.value);
            if (moment(birthDateValue || '', 'DD.MM.YYYY', true).add(16, 'year').isAfter(date)) {
                return {
                    customValidation: true,
                    message: 'Стаж начался в возрасте до 16 лет'
                };
            }

            return null;
        };
    }

    // Валидация даты выдачи СТС, ПТС и еПТС
    public documentDate(ignore = () => false, productionYearControlName: string = 'productionYear'): ValidatorFn {
        return (fc: AbstractControl) => {
            const value = TextMaskHelper.getDate(fc.value);
            const date = moment(value || '', 'DD.MM.YYYY');
            if (!date.isValid()) {
                return {
                    customValidation: true,
                    message: 'Укажите корректную дату'
                };
            }

            if (date.isAfter()) {
                return {
                    customValidation: true,
                    message: 'Документ не может быть выдан в будущем'
                };
            }

            const productionYearControlValue = (fc.parent as UntypedFormGroup)?.get(productionYearControlName);
            if (productionYearControlValue?.invalid) {
                return null;
            }

            const productionDate = moment(`01.01.${productionYearControlValue?.value}`, 'DD.MM.YYYY');
            if (productionDate.isValid() && productionDate.isAfter(date)) {
                fc.markAsTouched();
                return {
                    customValidation: true,
                    message: 'Дата выдачи документа не может быть ранее даты выпуска авто'
                };
            }

            return null;
        };
    }

    // Валидация контролла год выпуска авто
    public productionYear(ignore = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            const stsDate = (fc.parent as UntypedFormGroup)?.get('stsDate');
            stsDate?.updateValueAndValidity();
            const ptsDate = (fc.parent as UntypedFormGroup)?.get('ptsDate');
            ptsDate?.updateValueAndValidity();
            const ePtsDate = (fc.parent as UntypedFormGroup)?.get('ePtsDate');
            ePtsDate?.updateValueAndValidity();
            return null;
        };
    }

    // Валидация даты выдачи паспорта
    public passportDate(birthdateFieldName: string, ignore = () => false): ValidatorFn {
        return (fc: AbstractControl) => {
            if (ignore()) {
                return null;
            }

            const value = TextMaskHelper.getDate(fc.value);
            if (value == null) {
                return {
                    customValidation: true,
                    message: 'Укажите корректную дату выдачи паспорта'
                };
            }

            const date = moment(value, 'DD.MM.YYYY', true);
            if (!date.isValid() || date.isAfter()) {
                return {
                    customValidation: true,
                    message: 'Укажите корректную дату выдачи паспорта'
                };
            }

            if (!moment(value || '', 'DD.MM.YYYY', true).add(100, 'years').isAfter()) {
                return {
                    customValidation: true,
                    message: 'Укажите корректную дату выдачи паспорта'
                };
            }

            const birthDate = (fc.parent as UntypedFormGroup)?.get(birthdateFieldName);

            if (birthDate && !birthDate.valid) {
                birthDate?.updateValueAndValidity();
                return null;
            }

            // TODO дата выдачи пасопрта н едолжна бытьс вязана с датой выдачи ВУ
            // const birthDateValue = birthDate ? TextMaskHelper.getDate(birthDate.value) : null;
            // if (birthDateValue && moment(birthDateValue, 'DD.MM.YYYY', true)
            //     .add(14, 'year')
            //     .add(-1, 'day')
            //     .isAfter(date)) {
            //     return {
            //         customValidation: true,
            //         message: 'Укажите корректную дату выдачи паспорта'
            //     };
            // }

            // // более 45 лет
            // const age45 = moment(moment(TextMaskHelper.getDate(birthDate?.value) || '', 'DD.MM.YYYY', true)
            //     .add(45, 'years')
            //     .add(-1, 'day')
            //     .toDate());
            // if (age45.isBefore() && !date.isAfter(age45)) {
            //     return {
            //         customValidation: true,
            //         message: 'Паспорт следует заменить при достижении 45 лет'
            //     };
            // }
            //
            // // 20 - 45 лет
            // const age20 = moment(moment(TextMaskHelper.getDate(birthDate?.value) || '', 'DD.MM.YYYY', true)
            //     .add(20, 'years')
            //     .add(-1, 'day')
            //     .toDate());
            // if (age45.isAfter() && age20.isBefore() && !date.isAfter(age20)) {
            //     return {
            //         customValidation: true,
            //         message: 'Паспорт следует заменить при достижении 20 лет'
            //     };
            // }

            // 18 - 20 лет
            // const age14 = moment(moment(TextMaskHelper.getDate(birthDate?.value) || '', 'DD.MM.YYYY', true).add(14, 'years').toDate());
            // const age18 = moment(moment(TextMaskHelper.getDate(birthDate?.value) || '', 'DD.MM.YYYY', true).add(18, 'years').toDate());
            // if (age20.isAfter() && age18.isBefore() && !date.isAfter(age14)) {
            //     return {
            //         customValidation: true,
            //         message: 'Укажите корректную дату выдачи паспорта'
            //     };
            // }

            return null;
        };
    }

    // Обязательный выбор из списка, полученного с сервиса daData
    public daDataRequired(daDataFieldName: string): ValidatorFn {
        return (fc: AbstractControl) => {
            const cityDaData = (fc.parent as UntypedFormGroup)?.get(daDataFieldName)?.value;

            if (!(Number(cityDaData?.fias_level) >= 8 || (Number(cityDaData?.fias_level) === 7 && cityDaData?.house != null))) {
                return {
                    customValidation: true,
                    message: 'Укажите адрес до дома и номер квартиры (если есть)'
                };
            }

            return null;
        };
    }

    // Обязательный выбор из списка, полученного с сервиса daData
    public daDataOnlyCityRequired(daDataFieldName: string): ValidatorFn {
        return (fc: AbstractControl) => {
            const cityDaData = (fc.parent as UntypedFormGroup)?.get(daDataFieldName)?.value;

            if (Number(cityDaData?.fias_level) < 1) {
                return {
                    customValidation: true,
                    message: 'Укажите город регистрации'
                };
            }

            return null;
        };
    }

    // Валидация контролла В собственности с какого года
    public ownedSince(productionYear: string, ignore = () => false): ValidatorFn {
        return (fc: AbstractControl) => {

            const value = fc.value;
            if (value == null || moment().year() < Number(value)) {
                return {
                    customValidation: true,
                    message: 'Укажите корректный год'
                };
            }

            const date = moment('31.12.' + value, 'DD.MM.YYYY', true);
            if (!date.isValid()) {
                return {
                    customValidation: true,
                    message: 'Укажите корректный год'
                };
            }

            const productionYearFormControl = (fc.parent as UntypedFormGroup)?.get('productionYear');
            if (!productionYearFormControl?.valid) {
                return null;
            }

            if (value < productionYearFormControl.value) {
                return {
                    customValidation: true,
                    message: 'Автомобиль не может быть в собственности раньше чем был произведен.'
                };
            }

            return null;
        };
    }

    // Обязательный выбор из списка, полученного с сервиса daData
    public errorCode(isValidCode: boolean): ValidatorFn {
        return (fc: AbstractControl) => {

            if (!isValidCode) {
                return {
                    customValidation: true,
                    message: 'Вы ввели неверный смс код'
                };
            }

            return null;
        };
    }

}
