export enum ROUTES {

    // Главная страница
    MainPage = '',

    // Страница с данными авто
    AutoInfoPage = 'auto-info',

    // Страница после отправки новой марки авто
    SuccessNotMark = 'success-notmark',

    // Страница с данными водителей
    Drivers = 'drivers',

    // Страница с данными собственника
    Owner = 'owner',

    // Страница с данными собственника
    Offers = 'offers',

    // Страница с данными собственника и водителей
    OwnerAndDrivers = 'owner-and-drivers',

    // Страница поиска ссылки на оплату
    CreatePolicy = 'create-policy',

    // Страница после выбора оффера
    Success = 'success',

}
