<div class="notifications">
    <div *ngFor="let notification of notifications" class="notification">
        <ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
    </div>
</div>

<ng-template #notificationTpl let-notification="notification">

    <div id="liveToast" class="toast d-block" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
            <strong class="me-auto" [ngClass]="className(notification)">{{notification.title}}</strong>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" (click)="close(notification)"></button>
        </div>
        <div class="toast-body">
            {{notification.message}}
        </div>
    </div>

<!--    <div class="title" fxLayout="row" fxLayoutAlign="space-between center">-->
<!--        <div>{{notification.title}}</div>-->
<!--        <button mat-icon-button-->
<!--                (click)="close(notification)"-->
<!--                matTooltip="Close the notification"-->
<!--                matTooltipPosition="before">-->
<!--            <mat-icon>cancel</mat-icon>-->
<!--        </button>-->
<!--    </div>-->

<!--    <div class="message">{{notification.message}}</div>-->

</ng-template>
