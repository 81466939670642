<div class="has-validation" [class.form-floating]="label">
    <input [formControl]="inputControl"
           [ngClass]="{
            'is-invalid': control.invalid && control.touched
           }"
           class="form-control"
           [class.form-control-lg]="large"
           appIsNumeric
           [postfix]="postfix"
           [numericMaxLength]="numericMaxLength"
           [decimalSeparator]="decimalSeparator"
           [numericThousandSeparator]="numericThousandSeparator"
           [attr.maxLength]="maxLength"
           [readOnly]="isReadonly"
           [pattern]="pattern"
           type="text"
           inputmode="numeric"
           autocomplete="off"
           (blur)="blurControl($event)"
           [name]="name"
           [placeholder]="label ? label : placeholder">
    <label *ngIf="label">{{label}}</label>
    <app-form-field-error
        *ngIf="control?.invalid && control?.touched"
        [controlErrors]="control.errors"
        [messages]="messages">
    </app-form-field-error>
</div>
