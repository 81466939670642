<div class="row" [formGroup]="form">
    <div class="col-12 col-md-6 mb-3">
        <app-form-number-field formControlName="filterPrice" [isReadonly]="isLoading || isLoadingOffers"></app-form-number-field>
    </div>
    <div class="col-12 col-md-6 mb-3">
        <app-autocomplete
            formControlName="franchise"
            [isReadonly]="isLoading || isLoadingOffers"
            [typeaheadList]="kaskoService.franchiseList"
            [isDropdown]="true"></app-autocomplete>
    </div>
</div>
