import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {FormControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

// Модуль
import SwiperCore, {Scrollbar, Mousewheel} from 'swiper';

SwiperCore.use([Scrollbar, Mousewheel]);
import {environment} from '../../../../../environments/environment';

// Сервисы
import {FormService} from '../../../../shared/services/form.service';
import {KaskoService} from '../../../../shared/services/kasko.service';
import {YandexMetrikaService} from '../../../../shared/services/yandex-metrika.service';
import {CacheService} from '../../../../core/services/cache.service';
import {ValidationService} from '../../../../shared/services/validation.service';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {PermitRouteService} from '../../../../core/services/permit-route.service';

// Интерфейсы
import {ICarBrand} from '../../../../shared/interface/car-brands.interface';
import {ICarModel} from '../../../../shared/interface/car-models.interface';

// Перечисления
import {ROUTES} from '../../../../shared/enums/routes.enum';
import {CarDocumentTypesEnum} from '../../../../shared/enums/car-document-types.enum';
import {CarIdentificatorsEnum} from '../../../../shared/enums/car-identificators.enum';
import {DaData} from '../../../../shared/enums/da-data.enum';
import {getAlertModelId} from '../../../../shared/functions/getCarBrandAndModel';

@Component({
    selector: 'app-auto',
    templateUrl: './auto.component.html',
    styleUrls: ['./auto.component.scss']
})
export class AutoComponent implements OnInit, OnDestroy {

    constructor(public readonly formService: FormService,
                public readonly kaskoService: KaskoService,
                private readonly validationService: ValidationService,
                private readonly cacheService: CacheService,
                private readonly navigationService: NavigationService,
                private readonly permitRouteService: PermitRouteService,
                private readonly ym: YandexMetrikaService) {
        if (!this.permitRouteService.isPermitRoute(ROUTES.AutoInfoPage)) {
            this.navigationService.navigate(ROUTES.MainPage);
        } else {
            this.subscription.add(this.cacheService.saveCache().subscribe());
        }
    }

    protected readonly daDataSystemName = DaData;

    // Форма
    public form = (this.formService.form.get('carData') as UntypedFormGroup);
    // Форма если нет марки авто
    public withoutCarBrandForm = (this.formService.form.get('withoutCarBrand') as UntypedFormGroup);
    // Если выбрали "Нет моей марки или модели"
    public notMark = (this.formService.form.get('notMark') as UntypedFormControl);

    // Подписка
    public subscription = new Subscription();
    // Данные авто моделей
    public optionsModels!: ICarModel[];
    // Индикатор загрузки
    public isLoading = false;
    // Типы авто документа
    public carDocumentTypes = CarDocumentTypesEnum;
    // Идентификаторы авто
    public carIdentificators = CarIdentificatorsEnum;
    // Окружение
    public readonly environment = environment;

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        // При заходе на роут, делаем плавный переход вверх
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.ym.onYandexReachGoal('CAR_DATA_GOAL');
        this.subscriptionForm();
        const productionYear = this.form.get('productionYear')?.value;
        this.kaskoService.getOwnedSinceList(productionYear);
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.formService.subscriptionCarData.unsubscribe();
    }

    // --------------------------------------------------------------------------

    // Отправка формы
    public onSubmit(): void {
        this.form.markAllAsTouched();
        const isValidCarDataPage = this.validationService.validationAutoInfoPage();

        if (!isValidCarDataPage) {
            return;
        }

        this.navigationService.navigate(ROUTES.Drivers);
    }

    // Отправка формы
    public onSubmitNotMark(): void {
        this.withoutCarBrandForm.markAllAsTouched();
        const isValidNotMarkFormPage = this.validationService.validationNotMarkPage();

        if (!isValidNotMarkFormPage) {
            return;
        }

        this.subscription.add(
            this.kaskoService.saveClientNotMarkForm()
                .subscribe((res) => {
                    this.navigationService.navigate(ROUTES.SuccessNotMark);
                })
        );
    }

    // Выбрали бренд авто из списка
    public selectBrand(selectItem: TypeaheadMatch): void {
        this.form.get('modelId')?.disable();
        // Выбранные элемент бренда авто
        const carBrand: ICarBrand = selectItem?.item;
        // Применяем его ID к контроллу brandId
        // this.form.get('brandId')?.setValue(carBrand.brandId);
        // Сбрасываем предыдущее значение модели
        this.form.get('modelId')?.reset();
        // this.form.get('modelName')?.reset();
        // Выбираем HTMLElement элемент с ID = modelId

        // Получаем список моделей по выбранному бренду ID
        this.subscription.add(
            this.kaskoService.getCarModels(carBrand.brandId)
                .pipe(finalize(() => this.form.get('modelId')?.enable()))
                .subscribe()
        );
    }

    // Подписка на изенение формы
    private subscriptionForm(): void {
        this.subscription.add(
            this.form.get('productionYear')?.valueChanges.subscribe((value) => {
                const ownedSince = this.form.get('ownedSince');
                if (ownedSince?.value && ownedSince?.value < value) {
                    ownedSince.reset();
                }
                this.kaskoService.getOwnedSinceList(value);
            })
        );
    }

    protected readonly getAlertModelId = getAlertModelId;
}
