import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {ROUTES} from '../../../../../shared/enums/routes.enum';
import {FormService} from '../../../../../shared/services/form.service';
import {KaskoService} from '../../../../../shared/services/kasko.service';
import {NavigationService} from '../../../../../shared/services/navigation.service';
import {Subscription} from 'rxjs';
import {IOffer} from '../../../../../shared/interface/offers.interface';

@Component({
    selector: 'app-offer',
    templateUrl: './offer.component.html',
    styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit, OnDestroy {
    // Данные офера
    @Input() offer: any;
    // Приоритетная компания
    @Input() priorityCompany!: string;
    // Событие ввыбора оффера
    @Output() changeOffer: EventEmitter<IOffer> = new EventEmitter();

    constructor(public readonly formService: FormService,
                public readonly kaskoService: KaskoService,
                public readonly navigationService: NavigationService) {
    }

    // Индикатор загрузки
    public isLoading = false;
    // Подписка
    private subscriptions: Subscription[] = [];
    // Показать подарки
    public isShowPromocodes = false;
    // Показать описание
    public isShowDescription = false;

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    // --------------------------------------------------------------------------

    // Заказать
    public checkOrder(offer: any): void {
        this.kaskoService.selectedOffer = offer;
        this.isLoading = true;
        this.subscriptions.push(this.kaskoService.selectOffer(offer)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe((res) => {
                if (res.result && offer.isOffline) {
                    this.navigationService.navigate(ROUTES.Success);
                } else {
                    this.navigationService.navigate(ROUTES.CreatePolicy);
                    this.changeOffer.emit(offer);
                }
            }));
    }

}
