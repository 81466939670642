<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <ng-container *ngFor="let item of breadcrumbLinks; let index = index">
            <li *ngIf="!item.separator"
                class="breadcrumb-item"
                [class.active]="index.isActive"
                [routerLink]="item.isActive ? '/' + item.routeLink : null"
                queryParamsHandling="merge"
                routerLinkActive="active"
                #index="routerLinkActive">
                <a *ngIf="!index.isActive && item.routeLink && item.isActive" type="button" class="text-decoration-none">
                    <span class="d-none d-sm-block">{{ item.title }}</span>
                    <span class="d-block d-sm-none">{{ item.shortTitle }}</span>
                </a>
                <ng-container *ngIf="index.isActive || (item.routeLink && !item.isActive)">
                    <span class="d-none d-sm-block">{{ item.title }}</span>
                    <span class="d-block d-sm-none">{{ item.shortTitle }}</span>
                </ng-container>
            </li>
            <li *ngIf="item.separator">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </li>
        </ng-container>
    </ol>
</nav>
