<div class="has-validation" [class.form-floating]="label">
    <input [formControl]="inputControl"
           [ngClass]="{
            'is-invalid': control.invalid && control.touched
           }"
           [class.form-control-lg]="!label"
           class="form-control"
           [readOnly]="isReadonly"
           [type]="type"
           autocomplete="off"
           [attr.inputmode]="isNumberKeyboard ? 'numeric' : 'text'"
           [textMask]="{ mask: mask ? mask : false, guide: true, showMask: showMask }"
           [name]="name"
           [placeholder]="placeholder"
           (blur)="blurControl($event)">
    <label *ngIf="label">{{label}}</label>
    <app-form-field-error
        *ngIf="control.invalid && control.touched"
        [controlErrors]="control.errors"
        [messages]="messages">
    </app-form-field-error>
</div>
