<div class="form-floating has-validation" [class.form-floating]="label">
    <input [formControl]="inputControl"
           [ngClass]="{
            'is-invalid': control.invalid && control.touched
           }"
           id="testId"
           class="form-control"
           appIsDate
           [pattern]="pattern"
           [readonly]="isReadonly"
           type="text"
           inputmode="numeric"
           autocomplete="off"
           [textMask]="{ mask: dateMask }"
           [name]="name"
           [placeholder]="label"
           (click)="datepicker?.show()"
           (blur)="blurControl($event)">

    <label>{{label}}</label>
    <app-form-field-error
        *ngIf="control?.invalid && control?.touched"
        [controlErrors]="control.errors"
        [messages]="messages">
    </app-form-field-error>

    <input *ngIf="isShowCalendar && !isReadonly" #inputDate
           style="height: 0; width: 100%; pointer-events: none; padding: 0; opacity: 0;"
           type="text"
           class="form-control-plaintext"
           bsDatepicker
           [bsConfig]="bsConfig"
           (bsValueChange)="changeDate($event)">
</div>
