import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-network-status',
    templateUrl: './network-status.component.html',
    styleUrls: ['./network-status.component.scss']
})
export class NetworkStatusComponent implements OnInit {

    constructor() {
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
    }

    // --------------------------------------------------------------------------


    // Обновить страницу
    public reloadPage(): void {
        window.location.reload();
    }

}
