import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

// Перечисления
import { ROUTES } from '../../shared/enums/routes.enum';

// Сервисы
import { ValidationService } from '../../shared/services/validation.service';
import { KaskoService } from '../../shared/services/kasko.service';

@Injectable({
    providedIn: 'root'
})
export class PermitRouteService {

    constructor(private readonly validationService: ValidationService) {
    }

    public isPermitRoute(url: string): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let isPermit = false;
        switch (url) {
            case(ROUTES.MainPage): {
                isPermit = true;
                break;
            }
            case(ROUTES.AutoInfoPage): {
                isPermit = this.validationService.validationLicensePage();
                break;
            }
            case(ROUTES.Drivers): {
                isPermit = this.validationService.validationLicensePage()
                    && this.validationService.validationAutoInfoPage();
                break;
            }
            case(ROUTES.Owner): {
                isPermit = this.validationService.validationLicensePage()
                    && this.validationService.validationAutoInfoPage()
                    && this.validationService.validationDriversPage();
                break;
            }
            case(ROUTES.Offers): {
                // TODO поменять условие так, что бы активность зависила от того, изменился ли стейт формы
                isPermit = this.validationService.validationLicensePage()
                    && this.validationService.validationAutoInfoPage()
                    && this.validationService.validationDriversPage()
                    && this.validationService.validationOwnerPage()
                    && this.validationService.validationContacts();
                break;
            }
        }

        return isPermit;
    }
}
