import { Component, forwardRef, Inject, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormControl,
    FormControlDirective,
    FormControlName,
    FormGroupDirective,
    NG_VALUE_ACCESSOR,
    NgControl
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormService } from '../../services/form.service';
import { MASKS } from '../../constants/masks';
import { IMask } from '../../interface/masks.interface';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-form-license-field',
  templateUrl: './form-license-field.component.html',
  styleUrls: ['./form-license-field.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormLicenseFieldComponent),
        multi: true,
    }]
})
export class FormLicenseFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {

    // Заголовок
    @Input() label!: string;
    // Только для чтения
    @Input() isReadonly!: boolean;
    // Placeholder
    @Input() placeholder!: string;

    // Контрол изменяемого input
    public inputControl: UntypedFormControl = new UntypedFormControl(null);
    // Контрол
    public control!: UntypedFormControl;
    // Имя контрола
    public name!: string;
    // Тип
    public type = 'text';
    // Подписка на контрол
    private subscription: Subscription = new Subscription();
    // Сообщения валидации
    public messages: { [key: string]: any } = {};
    // Флаг, идет загрузка списка
    public isLoading = false;
    // Маски
    public mask: any;
    // Показываем клавиатуру "только цифры" в мобильных устройствах
    public isNumberKeyboard!: boolean;

    // Вызовем когда значение изменится
    private onTouched: any = () => {};

    // Вызовем при любом действии пользователя с контроллом
    private onChange: any = () => {};

    constructor(@Inject(Injector) private injector: Injector,
                public readonly formService: FormService,
                private readonly validationService: ValidationService) {
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        const injectedControl = this.injector.get(NgControl);
        this.name = injectedControl.name + 'controlName';

        switch (injectedControl.constructor) {
            case FormControlName: {
                this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
                break;
            }
            default: {
                this.control = (injectedControl as FormControlDirective).form as UntypedFormControl;
                break;
            }
        }

        // Применяем параметры контрола
        if (injectedControl.name) {
            this.initPropertyControl(injectedControl.name.toString());
        }

        // Подписка на изменение контрола
        this.subscription.add(
            this.inputControl.valueChanges.subscribe((value) => {
                // Очистка значения контролла от всех символов кроме букв и цифр
                // TODO Нужно переписать и очищать только от символов обозначенных в маске
                const cleanValue = value && this.type !== 'email'
                    ? this.type === 'phone'
                        ? value.match(/\d+/g).join('').substr(1)
                        : value.replace(/[\s`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
                    : value;
                this.control.setValue(cleanValue);
            })
        );
    }

    // Уничтожение
    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    // --------------------------------------------------------------------------

    // Применяем параметры контролла
    public initPropertyControl(injectedControlName: string): void {
        const propertyControl = this.formService.propertyControls[injectedControlName];
        if (propertyControl?.validation?.messages) {
            this.messages = propertyControl?.validation?.messages;
        }
        if (propertyControl?.label) {
            this.label = propertyControl.label;
        }
        if (propertyControl?.mask) {
            MASKS.filter((item: IMask) => item.systemName === propertyControl?.mask)
                .map((item) => this.mask = item.mask);
        }
        if (propertyControl?.type) {
            this.type = propertyControl?.type;
        }
        if (propertyControl?.placeholder) {
            this.placeholder = propertyControl?.placeholder;
        }
        if (propertyControl?.placeholder) {
            this.isNumberKeyboard = propertyControl?.onlyNumberKeyboard;
        }

        this.validationService.setControlValidation(propertyControl, this.control);

        return;
    }

    // Вызовет форма, если значение изменилось извне
    public writeValue(value: any): void {
        if (this.inputControl && this.inputControl.value !== value) {
            this.inputControl.setValue(value, {onlySelf: true, emitEvent: false});
            // this.onChange(value);
            // this.onTouched();
        }
    }

    // Сохраняем обратный вызов для изменений
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Сохраняем обратный вызов для "касаний"
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Установка состояния disabled
    public setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.inputControl.disable() : this.inputControl.enable();
    }

    // Событие при выходи из сонктрола
    public blurControl(event: Event): void {
        if (this.inputControl.value >= 8) {
            this.control.markAsTouched();
        }
    }

}
