import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Модули
import { SharedModule } from '../shared/shared.module';

// Сервисы и провайдеры
import { AppService } from './services/app.service';
import { CacheService } from './services/cache.service';
import { DadataService } from './services/dadata.service';

// Модуль ядра приложения
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        SharedModule
    ],
    providers: [
        AppService,
        CacheService,
        DadataService
    ]
})
export class CoreModule {
}
