import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

// Интферфейсы
import { IKaskoPolice } from '../../../../../shared/interface/cache.interface';
import { IApplication } from '../../../../../shared/interface/application.interface';

// Сервисы
import { KaskoService } from '../../../../../shared/services/kasko.service';
import { CacheService } from '../../../../../core/services/cache.service';

@Component({
    selector: 'app-cache-item',
    templateUrl: './cache-item.component.html',
    styleUrls: ['./cache-item.component.scss']
})
export class CacheItemComponent implements OnInit {
    // Элемент из кэша
    @Input() cacheItem!: IKaskoPolice;
    // Событие выбора элемента
    @Output() changeCacheItem = new EventEmitter<IApplication>();

    constructor(public readonly kaskoService: KaskoService,
                public readonly cacheService: CacheService) {
    }

    // Индикатор загрузки
    public isLoadingCacheItem = false;

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
    }
    // --------------------------------------------------------------------------

    // Выбрали элемент из кэша
    public selectCacheItem(item: IApplication): void {
        this.isLoadingCacheItem = true;
        this.changeCacheItem.emit(item);
    }
}
