<div class="row m-0 justify-content-between align-items-center">
    <div class="col logo">
        <a href="https://web.alfabank.ru/insurance/insurance-marketplace/">
            <img src="assets/landingFiles/images/logo.svg" alt="Каско онлайн" loading="lazy">
        </a>
    </div>
<!--    <div class="col alfaId text-end">-->
<!--        <div *ngIf="nameFromAlfaId" class="nameFromAlfaId">-->
<!--            {{ nameFromAlfaId }}-->
<!--        </div>-->
<!--        <button *ngIf="!nameFromAlfaId && stepValue !== 4"-->
<!--                type="button"-->
<!--                class="shadow-sm btn btn-primary alfaIdBtn my-2"-->
<!--                (click)="authAlfaId()"-->
<!--                [disabled]="isLoading">-->
<!--                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"-->
<!--                      aria-hidden="true"></span>-->
<!--                <span *ngIf="!isLoading">Войти с <img src="assets/landingFiles/images/alfaId.svg" alt="Alfa" loading="lazy"> ID</span>-->
<!--        </button>-->
<!--    </div>-->
</div>

<div class="container">
    <div class="btn-back-block" *ngIf="stepValue && isShowStepper">
        <a (click)="backBtnNavigate()">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="#F3F4F5"/>
                <path class="back-btn-arr" d="M11.5 17L23 17V15L11.5 15L15 11.5L13.5 10L8 16L13.5 22L15 20.5L11.5 17Z"/>
            </svg>
            Назад
        </a>
    </div>

    <div class="stepper" *ngIf="stepValue && isShowStepper">
        Шаг {{stepValue}} из 4
    </div>

    <div class="title" *ngIf="isShowHeader()">
        <h2>Подбор Каско</h2>
    </div>

    <div class="description" *ngIf="isShowHeader()">
        Узнайте цену Каско для своего автомобиля в 16&nbsp;страховых компаниях за&nbsp;2&nbsp;минуты
    </div>
</div>

<div class="container">
    <div class="kasko-app">
        <div *ngIf="isLoading" class="spinner-container d-flex justify-content-center align-items-center mt-4 mb-4">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Загрузка...</span>
            </div>
        </div>
        <div *ngIf="!isLoading && connectionStatus !== 'offline'">
            <app-notification></app-notification>
            <router-outlet></router-outlet>
        </div>
        <app-network-status *ngIf="connectionStatus === 'offline'"></app-network-status>
    </div>
</div>

<div *ngIf="isAdmin()">
    <button (click)="cacheService.resetCache()" class="btn">Очистить кэш</button>
    <button (click)="resetShowMiniLanding()" class="btn">Снова показать мини лендинг</button>
</div>

<div *ngIf="isModalShown" [config]="{ show: true }" bsModal #autoShownModal="bs-modal"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
            <div class="modal-body">

                <div class="mini-landing mobile show-mobile">

                    <div class="mini-landing-bg">
                        <img src="assets/landingFiles/images/mini-landing-header.png" alt="">
                    </div>
                    <div class="title">
                        КАСКО
                    </div>
                    <div class="post-title">
                        Рассчитайте, сколько будет стоить КАСКО для вашей машины: покажем предложения 16&nbsp;страховых компаний за&nbsp;2&nbsp;минуты
                    </div>

                    <div class="small-title">
                        Преимущества
                    </div>

                    <div class="tile-list">
                        <div class="item">
                            <div class="image">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.780288 12.3158C1.65342 6.21531 6.21531 1.65342 12.3158 0.780288C15.4298 0.334589 19.3246 0 24 0C28.6754 0 32.5702 0.334589 35.6842 0.780288C41.7847 1.65342 46.3466 6.21531 47.2197 12.3158C47.6654 15.4298 48 19.3246 48 24C48 28.6754 47.6654 32.5702 47.2197 35.6842C46.3466 41.7847 41.7847 46.3466 35.6842 47.2197C32.5702 47.6654 28.6754 48 24 48C19.3246 48 15.4298 47.6654 12.3158 47.2197C6.21531 46.3466 1.65342 41.7847 0.780288 35.6842C0.334589 32.5702 0 28.6754 0 24C0 19.3246 0.334589 15.4298 0.780288 12.3158Z" fill="#F3F4F5"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M34 24C34 29.5229 29.5228 34 24 34C18.4771 34 14 29.5229 14 24C14 18.4771 18.4771 14 24 14C29.5228 14 34 18.4771 34 24ZM22.5 28L29 21.5L27.5 20L22.5 25L20.5 23L19 24.5L22.5 28Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="item-content">
                                <div class="item-content-title">Всё онлайн</div>
                                <div class="sub-title">Не нужно идти в офис страховой</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="image">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.780288 12.3158C1.65342 6.21531 6.21531 1.65342 12.3158 0.780288C15.4298 0.334589 19.3246 0 24 0C28.6754 0 32.5702 0.334589 35.6842 0.780288C41.7847 1.65342 46.3466 6.21531 47.2197 12.3158C47.6654 15.4298 48 19.3246 48 24C48 28.6754 47.6654 32.5702 47.2197 35.6842C46.3466 41.7847 41.7847 46.3466 35.6842 47.2197C32.5702 47.6654 28.6754 48 24 48C19.3246 48 15.4298 47.6654 12.3158 47.2197C6.21531 46.3466 1.65342 41.7847 0.780288 35.6842C0.334589 32.5702 0 28.6754 0 24C0 19.3246 0.334589 15.4298 0.780288 12.3158Z"
                                        fill="#F3F4F5"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34ZM23 19V24C23 24.9443 23.4446 25.8334 24.2 26.4L26.8 28.1L28 26.5L25.4 24.8C25.1482 24.6111 25 24.3148 25 24V19H23Z"
                                          fill="black"></path>
                                </svg>
                            </div>
                            <div class="item-content">
                                <div class="item-content-title">За 2 минуты</div>
                                <div class="sub-title">Покажем предложения от 16 компаний</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="image">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.780288 12.3158C1.65342 6.21531 6.21531 1.65342 12.3158 0.780288C15.4298 0.334589 19.3246 0 24 0C28.6754 0 32.5702 0.334589 35.6842 0.780288C41.7847 1.65342 46.3466 6.21531 47.2197 12.3158C47.6654 15.4298 48 19.3246 48 24C48 28.6754 47.6654 32.5702 47.2197 35.6842C46.3466 41.7847 41.7847 46.3466 35.6842 47.2197C32.5702 47.6654 28.6754 48 24 48C19.3246 48 15.4298 47.6654 12.3158 47.2197C6.21531 46.3466 1.65342 41.7847 0.780288 35.6842C0.334589 32.5702 0 28.6754 0 24C0 19.3246 0.334589 15.4298 0.780288 12.3158Z" fill="#F3F4F5"/>
                                    <path d="M17 15.9999C15.8954 15.9999 15 16.8954 15 17.9999L30 17.9999C31.6569 17.9999 33 19.3431 33 20.9999L33 28.9999C34.1046 28.9999 35 28.1045 35 26.9999L35 17.9999C35 16.8954 34.1046 15.9999 33 15.9999L17 15.9999Z" fill="black"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 21.9999C13 20.8954 13.8954 19.9999 15 19.9999L29 19.9999C30.1046 19.9999 31 20.8954 31 21.9999L31 29.9999C31 31.1045 30.1046 31.9999 29 31.9999L15 31.9999C13.8954 31.9999 13 31.1045 13 29.9999L13 21.9999ZM24 25.9999C24 27.1045 23.1046 27.9999 22 27.9999C20.8954 27.9999 20 27.1045 20 25.9999C20 24.8954 20.8954 23.9999 22 23.9999C23.1046 23.9999 24 24.8954 24 25.9999ZM18 29.9999L15 29.9999L15 26.9999L18 29.9999ZM29 21.9999L26 21.9999L29 24.9999L29 21.9999Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="item-content">
                                <div class="item-content-title">Выгода до 50%</div>
                                <div class="sub-title">Чем аккуратнее водите, тем ниже цена</div>
                            </div>
                        </div>
                    </div>

                    <div class="small-title mb-0">
                        О партнёре
                    </div>

                    <div class="post-title">
                        Insаpp — бесплатный, быстрый, простой и&nbsp;надёжный сервис, который позволяет оформить выгодную страховку
                        всего
                        за&nbsp;2&nbsp;минуты.
                    </div>

                    <div class="control">
                        <button class="navigate-to-osago" (click)="hideModal()">
                            Оформить КАСКО
                        </button>
                    </div>

                </div>


            </div>
        </div>
    </div>
</div>

<!--<pre>{{formService.form.value | json}}</pre>-->

<!--alfa-->
