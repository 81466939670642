import { environmentDev } from './environment.common.dev';

export const environment = {
    apiKey: 'f273ada6ac954e7896cd23b6a10f2e72',
    partnerSystemName: 'alfa',
    cacheService: true,
    // Системное имя страховой компании, которая будет отображаться в приоритете
    priorityCompany: '',
    ...environmentDev
};
