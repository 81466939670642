import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-confirm-button',
    templateUrl: './confirm-button.component.html',
    styleUrls: ['./confirm-button.component.scss']
})
export class ConfirmButtonComponent implements OnInit, OnDestroy {

    // Событие при кподтверждении действия
    @Output() changeConform: EventEmitter<boolean> = new EventEmitter<boolean>();
    // Подсказка
    @Input() tooltipTitle!: string;

    constructor(private modalService: BsModalService) {}

    // Ссылка на модальное окно
    public modalRef?: BsModalRef;

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {

    }

    // Уничтожение
    public ngOnDestroy(): void {
    }

    // --------------------------------------------------------------------------

    // Открываем модальное окно
    public openModal(template: TemplateRef<any>): void {
        this.modalRef = this.modalService.show(template, {class: 'modal-sm modal-dialog-centered', backdrop: 'static'});
    }

    // Если подтвердили
    public confirm(): void {
        this.changeConform.emit(true);
        this.modalRef?.hide();
    }

    // Если отменили действие
    public decline(): void {
        this.modalRef?.hide();
    }

}
