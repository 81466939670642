<div class="drivers">
    <app-breadcrumb></app-breadcrumb>

    <h2 class="mb-2">Водители</h2>

    <div class="form-check form-switch mb-2">
        <input class="form-check-input" type="checkbox" role="switch" id="unlimitedDrivers" [formControl]="unlimitedDrivers">
        <label class="form-check-label" for="unlimitedDrivers">Без ограничений</label>
    </div>

    <div class="mb-4" [hidden]="!unlimitedDrivers.value">
        Управлять ТС может любой водитель. Полис без ограничений обычно стоит значительно дороже полиса с ограниченным списком водителей, допущенных
        к управлению
    </div>

    <form [formGroup]="formService.form" (ngSubmit)="onSubmit()" novalidate>

        <div *ngIf="formService.drivers.controls && formService.drivers.controls.length" [hidden]="unlimitedDrivers.value"  formArrayName="drivers">
            <div *ngFor="let driver of formService.drivers.controls; let index = index">
                <app-driver [formDriver]="driverForm(index)" [driverIndex]="index" [isLoading]="isLoading"></app-driver>
            </div>
        </div>

        <div class="row actions">
            <div class="col-12 col-sm-6 col-md-4 mb-3 mb-md-4" [hidden]="unlimitedDrivers.value" >
                <button type="button" (click)="formService.addDriver()"
                        [disabled]="formService.drivers.controls.length === 5" class="btn btn-outline-primary btn-add-driver btn-lg w-100">
                    Добавить водителя
                </button>
            </div>
            <div class="col-12 col-sm-6 col-md-4 mb-3">
                <button type="submit" class="btn btn-primary btn-lg w-100" [disabled]="isLoading">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    <span *ngIf="!isLoading">Продолжить</span>
                </button>
            </div>
        </div>

    </form>

</div>

<!--<pre>{{formService.form.value | json}}</pre>-->
