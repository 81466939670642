import { ICarIdentificators } from '../interface/car-identificators.interface';
import { CarIdentificatorsEnum } from '../enums/car-identificators.enum';

// Список всех идентификаторов авто
export const CAR_IDENTIFICATORS: ICarIdentificators[] = [
    {
        systemName: CarIdentificatorsEnum.VIN,
        value: 'VIN'
    },
    {
        systemName: CarIdentificatorsEnum.ChassisNumber,
        value: 'Номер шасси'
    },
    {
        systemName: CarIdentificatorsEnum.BodyNumber,
        value: 'Номер кузова'
    }
];
