import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
    @Output() changeTimerValue: EventEmitter<number> = new EventEmitter<number>();
    @Input() min = 0;
    @Input() sec = 30;
    // Текс таймера
    @Input() timerText = '';
    // Подписка
    public destroy = new Subject();
    public paymentCountdown = '00:00';
    private countdownInterval: any;

    constructor() {
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        // this.paymentCountdown = this.min + ':' + this.sec;
        this.startPaymentCountdown();
    }

    // --------------------------------------------------------------------------

    stopTimer(): void {
        this.paymentCountdown = (this.min.toString().length === 2 ? this.min : '0' + this.min
            + ':' + (this.sec?.toString().length === 2 ? this.sec : '0' + this.sec)).toString();
    }

    // Счетчик
    public startPaymentCountdown(): void {
        this.paymentCountdown = (this.min.toString().length === 2 ? this.min : '0' + this.min
            + ':' + (this.sec?.toString().length === 2 ? this.sec : '0' + this.sec)).toString();

        // начинаем обратный отсчет
        this.countdownInterval = setInterval(() => {
            const timeParts = this.paymentCountdown.split(':');
            let minutes = +timeParts[0];
            let seconds = +timeParts[1];
            this.changeTimerValue.emit(minutes ? minutes * seconds : seconds);
            if (minutes === 0 && seconds === 0) {
                clearInterval(this.countdownInterval);
                return;
            }

            if (seconds === 0) {
                minutes--;
                seconds = 59;
            } else {
                seconds--;
            }

            this.paymentCountdown =
            `${minutes.toString().padStart(this.min, this.sec.toString())}:${seconds.toString().padStart(this.min, this.sec.toString())}`;
            }, 1000);
    }

}
