import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

// Компоненты
import { AppComponent } from './app.component';

// Модули
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { KaskoModule } from './modules/kasko/kasko.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { SwiperModule } from 'swiper/angular';

// Основной модуль
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        KaskoModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        SwiperModule
    ],
    providers: [BsModalRef],
    bootstrap: [AppComponent]
})
export class AppModule {
}
