import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormService } from '../../../../../shared/services/form.service';

@Component({
    selector: 'app-without-license-modal',
    templateUrl: './without-license-modal.component.html',
    styleUrls: ['./without-license-modal.component.scss']
})
export class WithoutLicenseModalComponent implements OnInit {

    constructor(public readonly bsModalRef: BsModalRef,
                public readonly formService: FormService) {
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
    }

    // --------------------------------------------------------------------------

    // продолжаем без номера авто
    public processedWithoutLicenseNumber(): void {
        this.formService.resetForm();
        this.formService.form.get('withoutLicenseNumber')?.setValue(true);
        this.formService.form.get('license')?.clearValidators();
        this.bsModalRef?.hide();
    }
}
