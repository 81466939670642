<button (click)="openModal(template)" type="button" class="btn-close"
        aria-label="Close"
        [tooltip]="tooltipTitle ? tooltipTitle : ''"
        placement="left"></button>
<ng-template #template>
    <div class="modal-body text-center">
        <p>Подтвердите ваши действия</p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
            <button class="btn btn-outline-dark me-md-2" type="button" (click)="confirm()" >Да</button>
            <button class="btn btn-outline-dark" type="button" (click)="decline()" >Нет</button>
        </div>
    </div>
</ng-template>
