import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

const defaultCb = (value: any) => !!value;

// Зависимые контролы в форме
export function subControl(
    master: AbstractControl,
    slave: AbstractControl,
    callback?: (value: any) => boolean,
    defaultValue: any = null,
): Subscription {

    const subscription = new Subscription();

    const check = () => {
        const cb = callback || defaultCb;
        if (master.disabled) {
            slave.disable();
        } else {
            if (cb(master.value)) {
                slave.enable();
            } else {
                slave.disable();
            }
        }
    };

    subscription.add(
        master.statusChanges.subscribe(() => check())
    );
    // subscription.add(
    //     master.valueChanges.subscribe(() => {
    //         // if (master.dirty) { slave.reset(defaultValue); }
    //         check();
    //     })
    // );
    check();

    return subscription;
}

// Зависимые контролы (только по значению)
// export function subControlValue(
//     group: FormGroup,
//     master: string,
//     slave: string,
//     callback?: (value: any) => boolean,
//     defaultValue: any = null,
// ): void {
//     const masterControl = group.get(master);
//     const slaveControl = group.get(slave);
//     const check = () => {
//         const masterValue = group.getRawValue()[master];
//         const cb = callback || defaultCb;
//         if (cb(masterValue)) {
//             slaveControl.enable();
//         } else {
//             slaveControl.disable();
//         }
//     };
//
//     masterControl.statusChanges.subscribe(() => {
//         check();
//     });
//     masterControl.valueChanges.subscribe(() => {
//         if (masterControl.dirty) { slaveControl.reset(defaultValue); }
//         check();
//     });
//     check();
// }

// Контрол от внешнего условия
export function subFormControl(
    group: UntypedFormGroup,
    control: AbstractControl,
    callback: (value: any) => boolean,
): void {

    const check = () => {
        const cmp = callback(control.value);
        if (cmp && control.disabled) {
            control.enable();
        }
        if (!cmp && control.enabled) {
            control.disable();
        }
    };

    group.statusChanges.subscribe(() => {
        check();
    });
    check();
}

export function compareFormData(): boolean {
    return true;
}
