import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { INotification } from '../interface/notification.interface';
import { NotificationType } from '../enums/notification-type.enum';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

    private subject = new Subject<INotification>();
    private idx = 0;

    constructor() { }

    public getObservable(): Observable<INotification> {
        return this.subject.asObservable();
    }

    public info(title: string, message: string, timeout = 3000): void {
        this.subject.next({ id: this.idx++, type: NotificationType.info, title, message, timeout });
    }

    public success(title: string, message: string, timeout = 3000): void {
        console.log('success');
        this.subject.next({ id: this.idx++, type: NotificationType.success, title, message, timeout });
    }

    public warning(title: string, message: string, timeout = 3000): void {
        this.subject.next({ id: this.idx++, type: NotificationType.warning, title, message, timeout });
    }

    public error(title: string, message: string, timeout = 3000): void {
        this.subject.next({ id: this.idx++, type: NotificationType.error, title, message, timeout });
    }
}
