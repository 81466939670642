import { Component } from '@angular/core';

// Перечисления
import { ROUTES } from '../../enums/routes.enum';

// Сервисы
import { PermitRouteService } from '../../../core/services/permit-route.service';
import { NavigationService } from '../../services/navigation.service';
import { Observable } from 'rxjs';
import { Router, UrlTree } from '@angular/router';
import { ValidationService } from '../../services/validation.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

    public breadcrumbLinks = [
        {
            routeLink: ROUTES.AutoInfoPage,
            title: 'Автомобили',
            shortTitle: 'Шаг 1',
            isActive: this.isPermitLink(ROUTES.AutoInfoPage)
        },
        {
            separator: true,
        },
        {
            routeLink: ROUTES.Drivers,
            title: 'Водители',
            shortTitle: 'Шаг 2',
            isActive: this.isPermitLink(ROUTES.Drivers)
        },
        {
            separator: true,
        },
        {
            routeLink: ROUTES.Owner,
            title: 'Собственник',
            shortTitle: 'Шаг 3',
            isActive: this.isPermitLink(ROUTES.Owner)
        },
        {
            separator: true,
        },
        {
            routeLink: ROUTES.Offers,
            title: 'Оформление',
            shortTitle: 'Шаг 4',
            isActive: this.isPermitLink(ROUTES.Offers)
        }
    ];

    constructor(public readonly permitRouteService: PermitRouteService,
                private readonly navigationService: NavigationService,
                private readonly validationService: ValidationService,
                private readonly router: Router) {
    }

    // Переход по роуту
    public navigate(route: string): void {
        this.navigationService.navigate(route);
    }

    // Проверяем активность ссылки
    public isPermitLink(url: string): boolean {
        let isPermit = false;
        switch (url) {
            case(ROUTES.MainPage): {
                isPermit = true;
                break;
            }
            case(ROUTES.AutoInfoPage): {
                isPermit = this.validationService.validationLicensePage() || this.router.url.search(ROUTES.Drivers) > 0;
                break;
            }
            case(ROUTES.Drivers): {
                isPermit = this.router.url.search(ROUTES.Owner) > 0 || this.router.url.search(ROUTES.Offers) > 0;
                break;
            }
            case(ROUTES.Owner): {
                isPermit = this.router.url.search(ROUTES.Offers) > 0;
                break;
            }
            case(ROUTES.Offers): {
                isPermit = false;
                break;
            }
        }

        return isPermit;
    }

}
