import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

// Модули
import { ReactiveFormsModule } from '@angular/forms';

// Компоненты и директивы
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { IsNumericDirective } from './directives/number.directive';
import { DateDirective } from './directives/date.directive';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { FormNumberFieldComponent } from './components/form-number-field/form-number-field.component';
import { FormDateFieldComponent } from './components/form-date-field/form-date-field.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { FormTextFieldComponent } from './components/form-text-field/form-text-field.component';
import { ConfirmButtonComponent } from './components/confirm-button/confirm-button.component';
import { ModalInvalidProductionYearComponent } from './components/modal-invalid-production-year/modal-invalid-production-year.component';
import { LicenseDirective } from './directives/license.directive';
import { TimerComponent } from './components/timer/timer.component';
import { FormLicenseFieldComponent } from './components/form-license-field/form-license-field.component';
import { NetworkStatusComponent } from './components/network-status/network-status.component';
import { NotificationComponent } from './components/notification/notification.component';

// Пайпы

// Сервисы и провайдеры
import { FormService } from './services/form.service';
import { NavigationService } from './services/navigation.service';
import { TextMaskModule } from 'angular2-text-mask';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {PolicyDataComponent} from './components/policy-data/policy-data.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {SelectedOfferComponent} from './components/selected-offer/selected-offer.component';
import {EditInfoComponent} from './components/policy-data/edit-info/edit-info.component';
import {OfferPlaceholderComponent} from './components/offer-placeholder/offer-placeholder.component';

@NgModule({
    declarations: [
        BreadcrumbComponent,
        IsNumericDirective,
        AutocompleteComponent,
        FormFieldErrorComponent,
        FormNumberFieldComponent,
        FormDateFieldComponent,
        DateDirective,
        ConfirmButtonComponent,
        FormTextFieldComponent,
        ModalInvalidProductionYearComponent,
        LicenseDirective,
        TimerComponent,
        FormLicenseFieldComponent,
        NetworkStatusComponent,
        NotificationComponent,
        PolicyDataComponent,
        SelectedOfferComponent,
        EditInfoComponent,
        OfferPlaceholderComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        RouterModule,
        TypeaheadModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TextMaskModule,
        BsDatepickerModule,
        NgOptimizedImage,
        CollapseModule
    ],
    exports: [
        BreadcrumbComponent,
        IsNumericDirective,
        AutocompleteComponent,
        FormFieldErrorComponent,
        FormNumberFieldComponent,
        FormDateFieldComponent,
        DateDirective,
        ConfirmButtonComponent,
        FormTextFieldComponent,
        LicenseDirective,
        TimerComponent,
        FormLicenseFieldComponent,
        NetworkStatusComponent,
        NotificationComponent,
        PolicyDataComponent,
        SelectedOfferComponent,
        OfferPlaceholderComponent,
    ],
    providers: [
        FormService,
        NavigationService,
        BsModalRef
    ]
})
export class SharedModule {
}
