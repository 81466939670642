import {Component, Input} from '@angular/core';
import {FormService} from '../../services/form.service';
import {AppService} from '../../../core/services/app.service';
import {KaskoService} from '../../services/kasko.service';

@Component({
    selector: 'app-policy-data',
    templateUrl: './policy-data.component.html',
    styleUrls: ['./policy-data.component.scss']
})
export class PolicyDataComponent {

    @Input() isShowEditInfo = false;

    constructor(public readonly formService: FormService,
                public readonly appService: AppService,
                public readonly kaskoService: KaskoService) {
    }

    // Выбранный оффер
    public selectedOffer = this.kaskoService.selectedOffer;
    // Индикатор загрузки
    public isLoading!: boolean;


    // --------------------------------------------------------------------------
    // Инициализация

    // --------------------------------------------------------------------------

}
