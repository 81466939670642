import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription, timer} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {KaskoService} from '../../../../shared/services/kasko.service';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {ROUTES} from '../../../../shared/enums/routes.enum';
import {IOffer} from '../../../../shared/interface/offers.interface';

@Component({
    selector: 'app-create-policy',
    templateUrl: './create-policy.component.html',
    styleUrls: ['./create-policy.component.scss']
})
export class CreatePolicyComponent implements OnInit, OnDestroy {

    constructor(public readonly kaskoService: KaskoService,
                public readonly navigationService: NavigationService) {
        if (!kaskoService.selectedOffer) {
            this.navigationService.navigate(ROUTES.Offers);
        }
    }

    // Индикатор загрузки
    public isLoading = false;
    // Подписка
    private subscription = new Subscription();
    // Статус получения ссылки на оплату
    public isPaymentLinkResult = true;
    // Таймер
    public rxjsTimer = timer(1000, 1000);
    // Следим за таймеом
    public destroy = new Subject();
    // Значение таймера
    public timer!: number;

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.getPaymentLink();
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // --------------------------------------------------------------------------

    // Получаем ссылку на оплату
    public getPaymentLink(): void {
        this.subscription.add(
            this.kaskoService.getPaymentLinkRepeatedly()
                .subscribe((res) => {
                    if (res.result) {
                        this.kaskoService.paymentLink = res?.value?.kaskoPaymentLink;
                    } else {
                        this.isPaymentLinkResult = res.result;
                        // Если не удалось получить res.result на оплату, тогда показываем офферов и снова даем возможность выбора
                        // Текущего оффера нужно убрать из списка
                        this.timer = 0;
                        this.destroy.next(0);
                        this.destroy.complete();
                        this.updateOffersList();
                    }
                })
        );
    }

    // Снова берем список офферов, но уже без текушего
    private updateOffersList(): void {
        this.kaskoService.offers = [];
        this.kaskoService.allOffers = [];

        this.subscription.add(
            this.rxjsTimer
                .pipe(
                    takeUntil(this.destroy)
                )
                .subscribe(val => {
                    this.timer = val;

                    if (this.timer >= 60) {
                        this.destroy.next(0);
                        this.destroy.complete();
                    }
                })
        );

        this.isLoading = true;
        this.subscription.add(
            this.kaskoService.updateOffersList()
                .pipe(
                    finalize(() => this.isLoading = false)
                )
                .subscribe((offers) => {
                    console.log(offers);
                })
        );
    }

    // Выбрали оффер
    public onChangeOffer(event: IOffer): void {
        this.kaskoService.paymentLink = '';
        this.isPaymentLinkResult = true;
    }

}
