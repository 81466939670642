import {
    Component,
    DoCheck,
    forwardRef,
    Inject,
    Injector,
    Input, OnDestroy,
    OnInit,
} from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormControl, FormControlDirective, FormControlName,
    FormGroupDirective, NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';

// Сервисы
import { FormService } from '../../services/form.service';
import { ValidationService } from '../../services/validation.service';

@Component({
    selector: 'app-form-number-field',
    templateUrl: './form-number-field.component.html',
    styleUrls: ['./form-number-field.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormNumberFieldComponent),
        multi: true,
    }],
})
export class FormNumberFieldComponent
    implements ControlValueAccessor, OnInit, OnDestroy, DoCheck {

    // Заголовок
    @Input() label!: string;
    // Только для чтения
    @Input() isReadonly!: boolean;
    // Максимальное числовое значение
    @Input() maxLength!: number;
    // Количество знаков
    @Input() numericMaxLength!: number;
    // Разделитель дробных чисел
    @Input() decimalSeparator!: string;
    // Разделитель тысячных чисел
    @Input() numericThousandSeparator!: string;
    // Значение подставляемое после числа
    @Input() postfix!: string;

    // Контрол изменяемого input
    public inputControl: UntypedFormControl = new UntypedFormControl(null);
    // Контрол
    public control!: UntypedFormControl;
    // Имя контрола
    public name!: string | number | null;
    // Подписка на контрол
    private subscription: Subscription = new Subscription();
    public subscriptions: Subscription[] = [];
    // Подписка на контрол поиска
    private searchSubscription!: Subscription;
    // Сообщения валидации
    public messages: {[key: string]: any} = {};
    // Флаг валидности контрола
    private valid!: boolean;
    // Флаг, идет загрузка списка
    public isLoading = false;
    // Паттерн - только числа
    public pattern = '[0-9]+';
    // Placeholder
    public placeholder!: string;
    // Размер контрола
    public large = false;

    constructor(@Inject(Injector) private injector: Injector,
                public readonly formService: FormService,
                private readonly validationService: ValidationService) {
    }

    // Вызовем когда значение изменится
    private onTouched: any = () => {};

    // Вызовем при любом действии пользователя с контроллом
    private onChange: any = () => {};

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        const injectedControl = this.injector.get(NgControl);
        this.name = injectedControl.name;

        switch (injectedControl.constructor) {
            case FormControlName: {
                this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
                break;
            }
            default: {
                this.control = (injectedControl as FormControlDirective).form as UntypedFormControl;
                break;
            }
        }

        // Если нам известно имя контролла, применяем для него параметры
        if (injectedControl?.name) {
            this.initPropertyControl(injectedControl.name.toString());
        }

        // Подписка на изменение контрола
        this.subscriptions.push(
            this.inputControl.valueChanges.subscribe((value) => {
                // Очистка значения контролла от пробелов и префикса
                const cleanValue = value && typeof value === 'string'
                    ? value.replace(this.postfix, '').replace(/\s/g, '').replace(/\D/g, '')
                    : value;
                this.control.setValue(cleanValue);
                this.onChange(cleanValue);
                this.onTouched();
            })
        );

    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    // --------------------------------------------------------------------------

    // Применяем параметры контролла
    public initPropertyControl(injectedControlName: string): void {
        const propertyControl = this.formService.propertyControls[injectedControlName];
        if (propertyControl?.validation?.messages) {
            this.messages = propertyControl?.validation?.messages;
        }
        if (propertyControl?.postfix) {
            this.postfix = propertyControl.postfix;
        }
        if (propertyControl?.pattern) {
            this.pattern = propertyControl.pattern;
        }
        if (propertyControl?.label) {
            this.label = propertyControl.label;
        }
        if (propertyControl?.numericThousandSeparator) {
            this.numericThousandSeparator = propertyControl.numericThousandSeparator;
        }
        if (propertyControl?.numericMaxLength) {
            this.numericMaxLength = propertyControl.numericMaxLength;
        }
        if (propertyControl?.maxLength) {
            this.maxLength = propertyControl.maxLength;
        }
        if (propertyControl?.placeholder) {
            this.placeholder = propertyControl.placeholder;
        }
        if (propertyControl?.large !== null) {
            this.large = propertyControl.large;
        }

        this.validationService.setControlValidation(propertyControl, this.control);

        return;
    }

    // Вызовет форма, если значение изменилось извне
    public writeValue(value: any): void {
        this.inputControl.setValue(value, { emitEvent: false });
    }

    // Сохраняем обратный вызов для изменений
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // Сохраняем обратный вызов для "касаний"
    public registerOnTouched(fn: any): void {
        // this.onTouched = fn;
    }

    // Установка состояния disabled
    public setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.inputControl.disable() : this.inputControl.enable();
    }

    public ngDoCheck(): void {
        if (this.control && this.control.touched && this.inputControl.untouched) {
            this.inputControl.markAsTouched();
        }
    }

    public isFieldValid(field: string): any {
        return !this.formService.form.get(field)?.valid && this.formService.form.get(field)?.touched;
    }

    // Событие при выходи из сонктрола
    public blurControl(event: Event): void {
        this.control.markAsTouched();
    }

}
