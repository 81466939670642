<div class="card mt-2 border-light h-auto shadow-none custom-rounded offer">
  <div class="card-body p-3 p-sm-4">
    <div class="row align-items-center">
      <div class="col-auto">
        <div class="m-0 d-flex align-items-center">
          <img src="./assets/images/partner-logo/{{selectedOffer.insurerType}}-logo.svg"
               class="insurance-company-logo" [alt]="selectedOffer.insurerName">
        </div>
      </div>
      <div class="col p-0">
        <div class="col px-0 px-sm-2 d-flex flex-column justify-content-center align-items-start">
          <div class="insurance-company-title mb-0 d-inline-block text-truncate">{{selectedOffer.insurerName}}</div>
          <div class="insurance-company-second-title text-secondary gray-string">Компания осуществляет проверку данных</div>
        </div>
      </div>
      <div class="col-12 mt-3 mt-sm-0 col-sm-auto d-flex text-end">

<!--        <div class="row">-->
<!--          &lt;!&ndash;          <div class="col">&ndash;&gt;-->
<!--          &lt;!&ndash;            <button type="button" class="btn btn-primary offers-btn"&ndash;&gt;-->
<!--          &lt;!&ndash;                    [disabled]="true">&ndash;&gt;-->
<!--          &lt;!&ndash;              Черновик полиса&ndash;&gt;-->
<!--          &lt;!&ndash;            </button>&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--          <div class="col">-->
<!--            -->
<!--          </div>-->
<!--        </div>-->
          <button *ngIf="paymentLink" (click)="pay()" type="button"
                  class="btn btn-primary offers-btn w-100 d-flex justify-content-center align-items-center">
              Перейти к оплате
          </button>

          <button *ngIf="!paymentLink" type="button"
                  class="btn btn-primary offers-btn w-100 d-flex justify-content-center align-items-center"
                  [disabled]="true">
              Создаем полис
              {{ paymentCountdown }}
          </button>

      </div>
    </div>
  </div>
</div>

<!--<pre>{{selectedOffer|json}}</pre>-->
