import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormService} from '../../../../../shared/services/form.service';
import {AppService} from '../../../../../core/services/app.service';
import {KaskoService} from '../../../../../shared/services/kasko.service';
import {NavigationService} from '../../../../../shared/services/navigation.service';
import {Subscription} from 'rxjs';
import {IFranchise} from '../../../../../shared/interface/franchise.interface';
import {debounceTime} from "rxjs/operators";

@Component({
    selector: 'app-filter-offers',
    templateUrl: './filter-offers.component.html',
    styleUrls: ['./filter-offers.component.scss']
})
export class FilterOffersComponent implements OnInit, OnDestroy {
    @Output() changeFilterPrice: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() isLoadingOffers = false;

    constructor(public readonly formService: FormService,
                public readonly appService: AppService,
                public readonly kaskoService: KaskoService,
                public readonly navigationService: NavigationService) {
    }

    // Форма
    public form: FormGroup = this.formService.formFilter;

    // Подписка
    private subscriptions: Subscription[] = [];
    // Индикатор загрузки
    public isLoading = false;

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.formInit();
        this.kaskoService.franchiseList = this.kaskoService.getFranchiseList();
        this.subscribeFilter();
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    // --------------------------------------------------------------------------

    // Инициализация формы
    public formInit(): void {
        const price = this.formService.form.get('carData')?.get('price')?.value;

        this.form.get('filterPrice')?.setValue(price);
    }

    // Подписка на изменения фильтра
    public subscribeFilter(): void {
        const {
            filterPrice,
            franchise
        } = this.form.controls;

        let filterChanged = false;

        this.subscriptions.push(
            filterPrice.valueChanges.pipe(
                debounceTime(500)
            ).subscribe((value) => {
                if (filterPrice.valid) {
                    this.formService.form.get('carData')?.get('price')?.setValue(value);
                    this.changeFilterPrice.emit(filterChanged);
                }
                if (!filterChanged) {
                    filterChanged = true; // Устанавливаем флаг после первого изменения
                }
            })
        );

        this.subscriptions.push(
            franchise.valueChanges.subscribe((value) => {
                this.kaskoService.filterOffersByFranchise(value);
            })
        );
    }

}
