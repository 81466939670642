import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    UntypedFormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';

// Сервисы
import { FormService } from '../../../../../shared/services/form.service';
import { KaskoService } from '../../../../../shared/services/kasko.service';

// Перечисления
import { DaData } from '../../../../../shared/enums/da-data.enum';

@Component({
    selector: 'app-driver',
    templateUrl: './driver.component.html',
    styleUrls: ['./driver.component.scss'],
})
export class DriverComponent implements OnInit, OnDestroy {

    // Форма водителя
    @Input() formDriver!: UntypedFormGroup;
    // Индекс текущего водителя
    @Input() driverIndex!: number;
    // Индикатор зарузки
    @Input()  isLoading!: boolean;
    // Подписка на форму drivers
    public subscriptionDrivers: Subscription = new Subscription();

    constructor(public readonly formService: FormService,
                public readonly kaskoService: KaskoService) {
    }

    // DaData системные имена
    public daDataSystemName = DaData;

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.formService.subscribeFormDriver(this.formDriver);
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.formService.subscriptionDrivers.unsubscribe();
    }
    // --------------------------------------------------------------------------
}
