import { IMask } from '../interface/masks.interface';

// Список всех масок
export const MASKS: IMask[] = [
    {
        systemName: 'license',
        mask: [ /^[a-zA-Zа-яА-ЯёЁ]$/, ' ', /\d/, /\d/, /\d/, ' ', /^[a-zA-Zа-яА-ЯёЁ\{\[]$/, /^[a-zA-Zа-яА-ЯёЁ\{\[]$/, ' ', /\d/, /\d/, /\d/ ],
        clearMask: [ /^[a-zA-Zа-яА-ЯёЁ]$/, /\d/, /\d/, /\d/, /^[a-zA-Zа-яА-ЯёЁ\{\[]$/, /^[a-zA-Zа-яА-ЯёЁ\{\[]$/, /\d/, /\d/, /\d/ ]
    },
    {
        systemName: 'stsNumber',
        mask: [/\d/, /\d/, ' ', /^[0-9а-яА-ЯёЁ]$/, /^[0-9а-яА-ЯёЁ]$/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /^[0-9-*]$/, /^[0-9-*]$/],
        clearMask: [/\d/, /\d/, /^[0-9а-яА-ЯёЁ]$/, /^[0-9а-яА-ЯёЁ]$/, /\d/, /\d/, /\d/, /\d/, /^[0-9-*]$/, /^[0-9-*]$/]
    },
    {
        systemName: 'ptsNumber',
        mask: [ /\d/, /\d/,  ' ', /^[0-9а-яА-ЯёЁ]$/, /^[0-9а-яА-ЯёЁ]$/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/ ],
        clearMask: [ /\d/, /\d/, /^[0-9а-яА-ЯёЁ]$/, /^[0-9а-яА-ЯёЁ]$/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]
    },
    {
        systemName: 'ePtsNumber',
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
        clearMask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
    },
    {
        systemName: 'vinNumber',
        mask: (): any[] => {
            let index = 0;
            const result = [];
            while (index < 19) {
                if (index === 3 || index === 10) {
                    result.push(' ');
                } else {
                    result.push(/^[0-9a-jklmnpr-zKLMNPR-ZA-JKLMNPR-Z-*]$/);
                }
                index++;
            }
            return result;
        }
    },
    {
        systemName: 'driverLicense',
        mask: [ /\d/, /\d/, ' ', /^[0-9a-zA-Zа-яА-ЯёЁ\{\[]$/, /^[0-9a-zA-Zа-яА-ЯёЁ\{\[]$/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ],
        clearMask: [ /\d/, /\d/, /^[0-9a-zA-Zа-яА-ЯёЁ\{\[]$/, /^[0-9a-zA-Zа-яА-ЯёЁ\{\[]$/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]
    },
    {
        systemName: 'phone',
        mask: ['+', '7', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
        clearMask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
    },
    {
        systemName: 'passportLicense',
        mask: [/\d/, /\d/,  ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        clearMask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
    },
    {
        systemName: 'dateMask',
        mask: [ /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/ ],
        clearMask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
    }

];
