<div class="offers">
    <app-breadcrumb></app-breadcrumb>

    <h2 class="mb-4">Выберите компанию</h2>

    <app-policy-data [isShowEditInfo]="true"></app-policy-data>

    <app-filter-offers [isLoadingOffers]="isLoading" (changeFilterPrice)="changeFilterPrice($event)"></app-filter-offers>

    <div class="card mb-4 h-auto shadow-sm rounded-4" *ngIf="isLoading">
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <h5>Поиск предложений</h5>
                </div>
                <div class="col-auto">
                    <app-timer [min]="2" [sec]="0" ></app-timer>
                </div>
            </div>
            <div class="progress mb-2">
                <div class="progress-bar" role="progressbar" [style]="'width:' + (timer / 2) * 100 / 60 + '%;'"></div>
            </div>
        </div>
    </div>

    <!--  Здесь показывается приоритетный оффер если он указан в окружении партнера  -->
    <div class="offers-list" *ngIf="priorityCompany && isPriorityOfferExist">
        <ng-container *ngFor="let offer of kaskoService.offers; let i = index;">
            <app-offer *ngIf="offer && offer.insurerType === priorityCompany" [offer]="offer" [priorityCompany]="priorityCompany"></app-offer>
        </ng-container>
    </div>

    <div *ngIf="priorityCompany && isPriorityOfferExist && kaskoService.offers && kaskoService.offers.length > 0" class="text-center">
        <button type="button" (click)="showOtherCompanies = !showOtherCompanies" class="btn btn-outline-primary btn-add-driver btn-lg mb-4">
            Больше предложений
        </button>
    </div>

    <div class="offers-list" *ngIf="(kaskoService.offers || kaskoService.offers?.length) && (!priorityCompany || (priorityCompany && showOtherCompanies) || (priorityCompany && !isPriorityOfferExist))">
        <ng-container *ngFor="let offer of kaskoService.offers; let i = index;">
            <app-offer *ngIf="offer && offer.insurerType !== priorityCompany" [offer]="offer"></app-offer>
        </ng-container>
    </div>

<!--    <div class="offers-list mini-kasko" *ngIf="(kaskoService.offers && kaskoService.offers?.length) && (!priorityCompany || (priorityCompany && showOtherCompanies) || (priorityCompany && !isPriorityOfferExist))">-->
<!--        <div class="row">-->
<!--            <div class="col">-->
<!--                <h4 class="mb-4">Онлайн КАСКО</h4>-->
<!--            </div>-->
<!--            <div class="col text-end">-->
<!--                <svg [popover]="'Выгодное решение для практичных водителей включает в себя.'"-->
<!--                     triggers="mouseenter:mouseleave"-->
<!--                     width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 4C13.6266 4 11.3066 4.70379 9.33316 6.02236C7.35977 7.34094 5.8217 9.21509 4.91345 11.4078C4.0052 13.6005 3.76756 16.0133 4.23058 18.3411C4.6936 20.6689 5.83649 22.8071 7.51472 24.4853C9.19295 26.1635 11.3312 27.3064 13.6589 27.7694C15.9867 28.2324 18.3995 27.9948 20.5922 27.0866C22.7849 26.1783 24.6591 24.6402 25.9776 22.6668C27.2962 20.6935 28 18.3734 28 16C27.9966 12.8185 26.7312 9.76821 24.4815 7.51852C22.2318 5.26883 19.1815 4.00344 16 4ZM16 26C14.0222 26 12.0888 25.4135 10.4443 24.3147C8.79981 23.2159 7.51809 21.6541 6.76121 19.8268C6.00433 17.9996 5.8063 15.9889 6.19215 14.0491C6.578 12.1093 7.53041 10.3275 8.92894 8.92893C10.3275 7.53041 12.1093 6.578 14.0491 6.19215C15.9889 5.8063 17.9996 6.00433 19.8268 6.7612C21.6541 7.51808 23.2159 8.79981 24.3147 10.4443C25.4135 12.0888 26 14.0222 26 16C25.9971 18.6513 24.9426 21.1931 23.0679 23.0679C21.1931 24.9426 18.6513 25.9971 16 26ZM16 12C16.8284 12 17.5 11.3284 17.5 10.5C17.5 9.67157 16.8284 9 16 9C15.1716 9 14.5 9.67157 14.5 10.5C14.5 11.3284 15.1716 12 16 12ZM17 15C17 14.4477 16.5523 14 16 14C15.4477 14 15 14.4477 15 15V22C15 22.5523 15.4477 23 16 23C16.5523 23 17 22.5523 17 22L17 15Z" fill="#98A2B3"/>-->
<!--                </svg>-->
<!--            </div>-->
<!--        </div>-->
<!--        <ng-container *ngFor="let offer of kaskoService.offers; let i = index;">-->
<!--            <div *ngIf="!offer.isOffline">-->
<!--                <app-offer *ngIf="offer && offer.insurerType !== priorityCompany && !offer.isOffline" [offer]="offer"></app-offer>-->
<!--            </div>-->
<!--        </ng-container>-->
<!--    </div>-->

<!--    <div class="offers-list offline-kasko mt-4" *ngIf="(kaskoService.offers && kaskoService.offers?.length) && (!priorityCompany || (priorityCompany && showOtherCompanies) || (priorityCompany && !isPriorityOfferExist))">-->
<!--        <h4 class="mb-4">Полное КАСКО</h4>-->
<!--        <ng-container *ngFor="let offer of kaskoService.offers; let i = index;">-->
<!--            <div *ngIf="offer.isOffline">-->
<!--                <app-offer *ngIf="offer && offer.insurerType !== priorityCompany && offer.isOffline" [offer]="offer"></app-offer>-->
<!--            </div>-->
<!--        </ng-container>-->
<!--    </div>-->

    <div *ngIf="kaskoService.offers.length === 0 && getOffersFinish && !isLoading">
        <div class="empty-offers" role="alert">
            <div class="empty-offers-icon">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_1148_66595" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
                        <path d="M0.780288 12.3158C1.65342 6.21531 6.21531 1.65342 12.3158 0.780288C15.4298 0.334589 19.3246 0 24 0C28.6754 0 32.5702 0.334589 35.6842 0.780288C41.7847 1.65342 46.3466 6.21531 47.2197 12.3158C47.6654 15.4298 48 19.3246 48 24C48 28.6754 47.6654 32.5702 47.2197 35.6842C46.3466 41.7847 41.7847 46.3466 35.6842 47.2197C32.5702 47.6654 28.6754 48 24 48C19.3246 48 15.4298 47.6654 12.3158 47.2197C6.21531 46.3466 1.65342 41.7847 0.780288 35.6842C0.334589 32.5702 0 28.6754 0 24C0 19.3246 0.334589 15.4298 0.780288 12.3158Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_1148_66595)">
                        <rect width="48" height="48" fill="white"/>
                        <rect width="48" height="48" fill="#F3F4F5"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 28.5L22.5859 24.0001L18 19.5L19.5 18L24.0001 22.5859L28.5 18L30 19.5L25.4143 24.0001L30 28.5L28.5 30L24.0001 25.4143L19.5 30L18 28.5Z" fill="#B6BCC3"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 1C19.3724 1 15.525 1.33115 12.4575 1.7702C6.79676 2.58039 2.58039 6.79676 1.7702 12.4575C1.33115 15.525 1 19.3724 1 24C1 28.6276 1.33115 32.475 1.7702 35.5425C2.58039 41.2032 6.79676 45.4196 12.4575 46.2298C15.525 46.6688 19.3724 47 24 47C28.6276 47 32.475 46.6688 35.5425 46.2298C41.2032 45.4196 45.4196 41.2032 46.2298 35.5425C46.6688 32.475 47 28.6276 47 24C47 19.3724 46.6688 15.525 46.2298 12.4575C45.4196 6.79676 41.2032 2.58039 35.5425 1.7702C32.475 1.33115 28.6276 1 24 1ZM12.3158 0.780288C6.21531 1.65342 1.65342 6.21531 0.780288 12.3158C0.334589 15.4298 0 19.3246 0 24C0 28.6754 0.334589 32.5702 0.780288 35.6842C1.65342 41.7847 6.21531 46.3466 12.3158 47.2197C15.4298 47.6654 19.3246 48 24 48C28.6754 48 32.5702 47.6654 35.6842 47.2197C41.7847 46.3466 46.3466 41.7847 47.2197 35.6842C47.6654 32.5702 48 28.6754 48 24C48 19.3246 47.6654 15.4298 47.2197 12.3158C46.3466 6.21531 41.7847 1.65342 35.6842 0.780288C32.5702 0.334589 28.6754 0 24 0C19.3246 0 15.4298 0.334589 12.3158 0.780288Z" fill="#0B1F35" fill-opacity="0.08"/>
                    </g>
                </svg>
            </div>
            <h2 class="empty-offers-title">К сожалению,<br class="d-block d-sm-none;"> предложений нет</h2>
            <div class="empty-offers-text">
                Мы не получили предложения для вас от страховых компаний.
                Попробуйте изменить данные водителей | собственника или транспортного средства.
            </div>
        </div>
    </div>
<!--    <pre>{{ kaskoService.offers | json }}</pre>-->

</div>
