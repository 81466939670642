import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

// Сервисы
import { NavigationService } from '../../../../shared/services/navigation.service';
import { DadataService } from '../../../../core/services/dadata.service';
import { FormService } from '../../../../shared/services/form.service';
import { ValidationService } from '../../../../shared/services/validation.service';
import { CacheService } from '../../../../core/services/cache.service';
import { PermitRouteService } from '../../../../core/services/permit-route.service';
import { KaskoService } from '../../../../shared/services/kasko.service';

// Перечисления
import { ROUTES } from '../../../../shared/enums/routes.enum';
import { YandexMetrikaService } from '../../../../shared/services/yandex-metrika.service';

@Component({
    selector: 'app-drivers',
    templateUrl: './drivers.component.html',
    styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit, OnDestroy {

    constructor(public readonly formService: FormService,
                private readonly dadataService: DadataService,
                private readonly navigationService: NavigationService,
                private readonly validationService: ValidationService,
                private readonly cacheService: CacheService,
                private readonly permitRouteService: PermitRouteService,
                private readonly ym: YandexMetrikaService) {
        if (!this.permitRouteService.isPermitRoute(ROUTES.Drivers)) {
            this.navigationService.navigate(ROUTES.AutoInfoPage);
        } else {
            this.subscription.add(this.cacheService.saveCache().subscribe());
        }
    }

    // Форма
    public formDrivers = (this.formService.form.get('drivers') as UntypedFormArray);
    // Индикатор загрузки
    public isLoading = false;
    // Подписка
    public subscription = new Subscription();
    // Флаг - водители без ограничений
    public unlimitedDrivers = (this.formService.form.get('unlimitedDrivers') as UntypedFormControl);

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        // При заходе на роут, делаем плавный переход вверх
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.ym.onYandexReachGoal('DRIVERS_GOAL');

        this.subscribeForm();
    }

    // Уничтожение
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // --------------------------------------------------------------------------

    // Получаем FormGroup водителя по индексу
    public driverForm(index: number): UntypedFormGroup {
        return this.formDrivers.at(index) as UntypedFormGroup;
    }

    // Отправка формы
    public onSubmit(): void {
        this.formDrivers.markAllAsTouched();
        const isValidCarDataPage = this.validationService.validationAutoInfoPage();
        const isValidDriversPage = this.validationService.validationDriversPage();

        if (!isValidCarDataPage || !isValidDriversPage) {
            return;
        }

        this.navigationService.navigate(ROUTES.Owner);
    }

    // Подписка на изменение формы
    private subscribeForm(): void {
        // Если выбрано водители "Без ограничений", то форма водителей должна быть валидна
        this.subscription.add(
            this.unlimitedDrivers.valueChanges.subscribe((value) => {
                if (value) {
                    this.formDrivers.disable();
                    this.formDrivers.markAsUntouched();
                } else {
                    this.formDrivers.enable();
                    this.formDrivers.markAsUntouched();
                }
            })
        );
    }
}
