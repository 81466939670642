import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';

declare global {
    interface Window {
        ym: any;
    }
}

@Injectable({
    providedIn: 'root'
})
export abstract class YandexMetrikaService {
    private _clientId: any;
    private _counterId = environment.yandexMetrika;

    constructor() {
        this.ym = window.ym;

        if (this.ym) {
            this.ym(this._counterId, 'getClientID', (clientId: any) => {
                this._clientId = clientId;
            });
        }

    }

    public get clientId() {
        return this._clientId;
    }

    public get counterId() {
        return this._counterId;
    }

    // Яндекс метрика
    private readonly ym;

    // Отправка события в яндекс метрику
    public onYandexReachGoal(ymTarget: string, goalParams?: any): void {
        if (window.ym) {
            window.ym(environment.yandexMetrika, 'reachGoal', ymTarget);
            if (environment.yandexMetrikaPartner) {
                window.ym(environment.yandexMetrikaPartner, 'reachGoal', ymTarget);
            }
        }
        // this.metrika.fireEvent(ymTarget);

    }
}
