import { Component, OnInit } from '@angular/core';
import {YandexMetrikaService} from '../../../../shared/services/yandex-metrika.service';

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

    constructor(private readonly ym: YandexMetrikaService) {
    }

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnInit(): void {
        this.ym.onYandexReachGoal('SUCCESS_GOAL');
    }
    // --------------------------------------------------------------------------
}
